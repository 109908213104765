import axios from 'axios';
import React, { Component } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { base64ToUrl, redirect } from '../../../lib/utils';
import Toaster from "../../common/components/Toaster";
import { createCompanyProfile, fetchBillingUser, PlanDetails, UpgradePlan } from '../action';
var CryptoJS = require("crypto-js");

// var w

class BillingRegister extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      phone: "",
      GST: "",
      free: false,
      designation: "",
      contactperson: "",
      address: "",
      modalVisible: false,
      decodedString: '',
      country: '',
      loading: true,
      monthly: true,
      yearly: false,
      duration: null,
      subscription_type: ""

    }
  }
  handleChange = (event) => {
    const { value } = event.target;

    if (/^\d*$/.test(value)) {
      this.setState({ phone: value });
    }
  }

  componentDidMount() {
    this.props.billingUser(this.props.token.access);
    // const { latitude, longitude } = this.props;
    if (localStorage.getItem("latitude") !== null) {
      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${localStorage.getItem("latitude").toString()},${localStorage.getItem("longitude").toString()}&key=AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0
      `)
        .then(response => {
          const addressComponents = response.data.results[0].address_components;
          const countryComponent = addressComponents.find(component => component.types.includes('country'));
          const country = countryComponent.long_name;
          this.setState({ country, loading: false });
        })
        .catch(error => {
          console.error(error);
          this.setState({ loading: false });
        });
    };
    this.props.PlanDetails()

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.billinguser !== this.props.billinguser) {
      this.setState({
        name: this.props.billinguser.user && this.props.billinguser.user.company_name,
        phone: this.props.billinguser.user && this.props.billinguser.user.phone,
        email: this.props.billinguser.user && this.props.billinguser.user.email

      })
    }

  }




  onCreateOrder = (type, duration) => {
    console.log("STATE",this.state)
    if(this.props.billinguser.id==undefined){
      let requestBody = {
        "billing_name": this.state.name,
        "contact_no": this.state.phone,
        "email": this.state.email,
        "gst_no": this.state.GST,
        "address": this.state.address,
        "contact_person": this.state.contactperson,
        "designation": this.state.designation,
        "subscription_type": type,
        "duration": duration
  
  
      }
      this.props.createCompany(this.props.token.access, requestBody)
    }
    else{
      let requestBody2={
        "subscription_type": type,
        "duration": duration,
        "billing":this.props.billinguser.id

      }
      this.props.UpgradePlan(this.props.token.access,requestBody2)
    }
   
    this.setState({
      name: "",
      email: "",
      phone: "",
      GST: "",
    })



  }



  render() {
    return (
      <>
        <div>
          <Modal show={this.state.modalVisible} onHide={this.handleCloseModal} backdrop="static" keyboard={false} centered={true} size={'lg'}>
            <Modal.Body >
              <iframe src={this.state.decodedString} title="description" style={{ height: window.innerHeight / 1.3, width: "100%" }}></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="main-content-container p-4 container-fluid" >
          <Row style={{ position: "absolute", right: 0, top: 0 }} >
            <Col>
              <Toaster />
            </Col>
          </Row>
          {this.props.billinguser.active !== "YES" && <p>Comapny Profile</p>}

          <Card className='p-4'>
            <Card.Body className='font-medium'>
              {this.props.billinguser.active !== "YES" && <div >
                <Row>
                  <Col>
                    <Form.Label>Comapny Name <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Full Name" required  defaultValue={this.state.name}
                      onChange={event => { this.setState({ name: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Comapny Email <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required defaultValue={this.state.email}
                      onChange={event => { this.setState({ email: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Comapny Phone <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Your Number " required defaultValue={this.state.phone}
                      onChange={this.handleChange} maxLength={"10"} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" placeholder="Contact Person"  value={this.state.contactperson}
                      onChange={event => { this.setState({ contactperson: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" placeholder="Designation"  value={this.state.designation}
                      onChange={event => { this.setState({ designation: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>GST NO</Form.Label>
                    <Form.Control type="text" placeholder="Enter Your GST " value={this.state.GST}
                      onChange={event => { this.setState({ GST: event.target.value }); }} />
                  </Col>
                </Row>
                <Row>
                  <br />
                  <Col className='col-md-12'>
                    <label for="exampleFormControlTextarea1" class="form-label">Address</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.address} onChange={event => { this.setState({ address: event.target.value }) }}></textarea>
                  </Col>
                </Row>
                <p className='m-2'><span style={{ color: "red", fontSize: "15px" }}><b>Note:</b></span> Please provide all the information and click on the plan your want to choose .</p>
                <br />
              </div>}

              <section className="pricing-section" style={{ padding: "0" }}>

                <div className="container">
                  <div className="sec-title text-center">
                    <span className="title">Get plan</span>
                  </div>
                  <div>
                    <center> <div style={{ border: "0.5px solid grey", width: "19%", height: "50px", borderRadius: "20px", display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "-3rem" }}>
                      <Button variant={this.state.monthly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ monthly: true, yearly: false }) }}>Monthly</Button>
                      <Button variant={this.state.yearly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ yearly: true, monthly: false }) }}>Yearly</Button>
                    </div></center>
                  </div>
                  <br />
                  <div className="outer-box">
                  {this.props.plandetails && this.props.plandetails.results &&
                    <div className="row">
                      {this.props.billinguser.active == "" &&
                        <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                          <div className="inner-box">
                            <div className="icon-box">
                              <div className="icon-outer"><i className="fa fa-paper-plane"></i></div>
                            </div>
                            <div className="price-box">
                                         <div className="title">{this.props.plandetails.results[0].name}</div>
                                         <del className="price">₹100</del>


                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[0].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[0].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[0].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[0].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[0].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[0].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[0].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[0].monthly_submission}</b></li>

                                     </ul>
                            <div className="btn-box" style={{curser: "pointer"}}>
                              <a className="theme-btn" style={{ textDecoration: "none", curser: "pointer" }}
                                onClick={() => { this.onCreateOrder("Free", 30) }}>BUY plan</a>
                            </div>
                          </div>
                        </div>}



                      <div className={this.props.billinguser.active !== "" ? "pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" : "pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp"} data-wow-delay="400ms">
                        <div className="inner-box">
                          <div className="icon-box">
                            <div className="icon-outer"><i className="fa fa-bandcamp"></i></div>
                          </div>
                          <div className="price-box">
                                         <div className="title">{this.props.plandetails.results[1].name}</div>
                                         {this.state.monthly == true ? <h4 className="price">₹{this.props.plandetails.results[1].monthly_amount}</h4>
                                             : <h4 className="price">₹{this.props.plandetails.results[1].yearly_amount}</h4>
                                         }
                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[1].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[1].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[1].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[1].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[1].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[1].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[1].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[1].monthly_submission}</b></li>

                                     </ul>
                          <div className="btn-box">
                            <a className="theme-btn" style={{ textDecoration: "none", curser: "pointer" }} onClick={() => { this.onCreateOrder("Deluxe", this.state.monthly == true ? 30 : 365) }}>BUY plan</a>
                          </div>
                        </div>
                      </div>


                      <div className={this.props.billinguser.active !== "" ? "pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" : "pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp"} data-wow-delay="800ms">
                        <div className="inner-box">
                          <div className="icon-box">
                            <div className="icon-outer"><i className="fa fa-diamond"></i></div>
                          </div>
                          <div className="price-box">
                                     <div className="title">{this.props.plandetails.results[2].name}</div>
                                         {this.state.monthly == true ? <h4 className="price">₹{this.props.plandetails.results[2].monthly_amount}</h4>
                                             : <h4 className="price">₹{this.props.plandetails.results[2].yearly_amount}</h4>
                                         }
                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[2].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[2].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[2].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[2].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[2].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[2].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[2].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[2].monthly_submission}</b></li>

                                     </ul>
                          <div className="btn-box">
                            <a className="theme-btn" style={{ textDecoration: "none" }} onClick={() => { this.onCreateOrder("Ultimate", this.state.monthly == true ? 30 : 365) }}>BUY plan</a>
                          </div>
                        </div>
                      </div>
                      <div className={this.props.billinguser.active !== "" ? "pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" : "pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp"} data-wow-delay="800ms">
                        <div className="inner-box">
                          <div className="icon-box">
                            <div className="icon-outer"><i className="fa fa-anchor"></i></div>
                          </div>
                          <div className="price-box">
                                     <div className="title">Custom</div>
                                         {this.state.monthly == true ? <h4 className="price">Custom</h4>
                                             : <h4 className="price">Custom</h4>
                                         }
                                     </div> 
                                     <ul className="features">
                                         <li className="true">Forms-<b></b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b></b></li>
                                         <li className="false">Workflow-<b></b></li>
                                         <li className="false">Space-<b></b></li>
                                         <li className="false">Email-<b></b></li>
                                         <li className="false">Custom Form-<b></b></li>
                                         <li className="false">E-signature-<b></b></li>
                                         <li className="false"> Submission-<b></b></li>

                                     </ul>
                          <div className="btn-box">
                            <a className="theme-btn" style={{ textDecoration: "none" }} onClick={() => { this.onCreateOrder("Custom", this.state.monthly == true ? 30 : 365) }}>BUY plan</a>
                          </div>
                        </div>
                      </div>
                    </div>
  }
                  </div>
                </div>
              </section>
            </Card.Body>
          </Card>

        </div>

      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.usersReducer.token,
    billinguser: state.billingReducer.billinguser,
    plandetails:state.billingReducer.plandetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCompany: (acessToken, requestbody) => dispatch(createCompanyProfile(acessToken, requestbody)),
    billingUser: (accessToken) => dispatch(fetchBillingUser(accessToken)),
    UpgradePlan:(acessToken,requestBody2)=>dispatch(UpgradePlan(acessToken,requestBody2)),
    PlanDetails:()=>dispatch(PlanDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingRegister);
