import React, { Component } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import { getFieldValue } from '../../../lib/utils';
import { fetchData, fetchFields, fetchForms } from '../action';
import jsPDF from "jspdf"
import CustomFieldHandler from '../../customforms/customfields/FieldHandler';
import FieldHandler from './fields/FieldHandler';



const titleStyle = {
    fontSize: '20pt',
    marginBottom: '10pt'
};

const contentStyle = {
    fontSize: '12pt',
    lineHeight: '1.5'
};


class ExportDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportBtnText: "Export"
        }
        this.reportTemplateRef = React.createRef();
    }


    componentDidMount() {
        this.props.getFields(this.props.token.access, this.props.params.template_id);
        this.props.getData(this.props.token.access, this.props.params.id);
        this.props.getForm(this.props.token.access, this.props.params.template_id);
    }

    setExported

    generatePdf = () => {
        const doc = new jsPDF({
            format: 'a1',
            unit: 'px',
        });

        const totalPages = doc.internal.getNumberOfPages(); // Get the total number of pages

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i); // Set the current page
        }


        doc.html(this.reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('document');
            },

        });


    };

    render() {
        return (
            <>
                <Row className="main-content-container container-fluid p-4">
                    <Col>
                        <h6>Form Document</h6>
                    </Col>
                    <Col md="2">
                        <Button variant='success' style={{ width: "100%" }} onClick={this.generatePdf} >{this.state.exportBtnText}</Button>
                    </Col>
                </Row>

                <div style={{ width: "100%", display: "flex", justifyContent: "center" }} ref={this.reportTemplateRef}>

                    <div style={{ width: "80%", alignSelf: "center", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                        <div className="ctrlqAccent"></div>
                        <div className="ctrlqFormContent">



                            <div className="row">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    {Object.keys(this.props.form).length > 0 &&
                                        <>
                                            <div className="input-field col s12" style={{ paddingInlineStart: this.props.form.type == 'C' ? "5%" : 0 }}>
                                                <h4>{this.props.form.label}</h4>
                                                <p>{this.props.form.description}</p>
                                            </div>

                                        </>
                                    }

                                </div>
                            </div>
                            <hr />

                            <div className="main-content-container container-fluid pt-3 pb-3" >

                                {this.props.data.data && <Row className='d-flex justify-content-center'>

                                    <Col md='12'>
                                        <div >
                                            {this.props.form.type == 'C' ?
                                                <Row style={{ display: "flex", }} className='custom-form'>
                                                    {this.props.fields.length > 0 && this.props.fields.map((item, index) => {
                                                        return (
                                                            <Col md={item.style == '' ? 12 : item.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                                                key={index}>
                                                                <CustomFieldHandler
                                                                    data={item}
                                                                    type={item.type}
                                                                    Sharetoken={this.props.params.token}
                                                                    editable={true}
                                                                    placeholder={''}
                                                                    value={getFieldValue(item, this.props.data.data, false)}
                                                                    onChange={(data) => { }}
                                                                    onBlur={(data) => { }} />
                                                            </Col>
                                                        )

                                                    })}
                                                </Row>
                                                :
                                                <div>
                                                    <Row>
                                                        {this.props.fields.map((field, index) => {
                                                            return <FieldHandler
                                                                fieldData={field}
                                                                index={index}
                                                                value={getFieldValue(field, this.props.data.data, false)}
                                                                handleDataChange={(field_id, value) => { }}
                                                                onBlur={() => { }} />

                                                        })}
                                                    </Row>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>}
                            </div>


                        </div>
                    </div>

                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        token: state.usersReducer.token,
        data: state.formsReducer.data,
        form: state.formsReducer.form,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportDocument);