import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { FETCH_TEMPLATES, FETCH_TEAM_TEMPLATE_USERS, FETCH_TEAM_REPORTS } from "./actionTypes";

export function fetchTemplates(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/team/template/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_TEMPLATES, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchTeamTemplateUser(accessToken, templateId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/team/${templateId}/member/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_TEAM_TEMPLATE_USERS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchTeamReport(accessToken, templateId, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/team/${templateId}/reports/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_TEAM_REPORTS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}