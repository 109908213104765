import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import SearchableDropdown from '../../common/components/SearchableDropdown';
import { fetchUserList } from '../../common/action';
import { addProjectMember } from '../action';

class AddMemberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            options: ['OWNER', 'MAINTAINER', 'REPORTER'],
            level: 'OWNER'
        }
    }
    componentDidMount = () => {
        this.props.getUserList(this.props.token.access, 1)
    }

    handleAdd = () => {
        var requestBody = {
            "level": this.state.level,
            "user": this.state.user.id,
            "project": this.props.projectId
        }
        this.props.addProjectMember(this.props.token.access, requestBody, this.props.projectId)
        this.props.handleClose();
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <SearchableDropdown
                        userList={this.props.getUserList}
                        defaultValue={this.state.user.name !== undefined ? this.state.user.name : ''}
                        onChange={(data) => this.setState({ user: data })}
                    />
                    <Form.Select className='mt-3' title={'Select Role'} aria-label="Select Role" defaultValue={'OWNER'} onChange={(data) => this.setState({ level: data.target.value })} size={'sm'}
                    >
                        {this.state.options.map((row, index) => {
                            return (
                                <option value={row} key={index}>{row}</option>
                            )
                        })}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button onClick={() => { this.handleAdd(); }} type='submit' variant="success" size='sm' >ADD</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        userList: state.commonReducer.userList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserList: (accessToken, pageNo) => dispatch((fetchUserList(accessToken, pageNo))),
        addProjectMember: (accessToken, requestBody, projectId) => dispatch((addProjectMember(accessToken, requestBody, projectId)))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberModal);
