import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { createWorkflowState, fetchWorkflowDetails, fetchWorkflowStateList, removeWorkFlowState } from '../action'
import { formateDate, formateBooleanData } from '../../../lib/utils';
import WorkflowStateUpdateModal from './WorkflowStateUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';

class Workflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpdateModal: false,
            showDeleteModal: false,
            selectedData: {},
            label: '',
            description: '',
            completion: "",
            initial: false,
        }
    }

    componentDidMount() {
        this.props.fetchWorkflowStateList(this.props.token.access, this.props.params.id);
        this.props.getWorkflowData(this.props.token.access, this.props.params.id);
    }

    deleteAction(event) {
        this.props.deleteState(this.props.token.access, event.id);
    }

    handleCreateAction = (event) => {
        event.preventDefault()
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description,
            'workflow': this.props.params.id,
            "completion": this.state.completion,
            "is_initial": this.state.initial
        }
        this.setState({
            label: '',
            description: '',
            completion: ""
        })
        if (requestBody["label"].replace(/\s/g, '').length) {
            this.props.createWorkflowState(this.props.token.access, requestBody, this.props.params.id);
        }
        else {
            this.props.setNotification(true,"Please Provide Proper State Label", "danger")
        }

    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col md="8">
                                <h6 style={{ fontSize: 21 }}>Workflow: {this.props.workflowdetails.label}</h6>
                                <p>Dec: {this.props.workflowdetails.description}</p>
                            </Col>

                        </Row>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>State Label <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" value={this.state.label} placeholder="Enter State Label" required
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Description" required value={this.state.description}
                                                onChange={event => { this.setState({ description: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Completion</Form.Label>
                                            <Form.Control size="sm" type="number" placeholder="Enter Completion" value={this.state.completion}
                                                onChange={event => { this.setState({ completion: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Is Initial</Form.Label>
                                            <Form.Check checked={this.state.initial} type="switch" label="Is Initial State?"
                                                onChange={() => this.setState({ initial: !this.state.initial })} />
                                        </Col>
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className='mt-5 font-small'>
                                    <DataTable
                                        totalItemsCount={this.props.workflowStateList.count}
                                        onPageChange={(pageNumber) => this.props.fetchWorkflowStateList(this.props.token.access, pageNumber)}
                                        headers={[`State Name`, `Description`, `Completion`, `Initial State`, `Created On`, `Last Modified`, `Action`]}
                                        body={this.props.workflowStateList.results.map((row, index) => {
                                            return [
                                                row.label,
                                                row.description,
                                                row.completion,
                                                formateBooleanData(row.is_initial),
                                                formateDate(row.date_added),
                                                formateDate(row.date_updated),
                                                <Row>
                                                    <Col>
                                                        <Button className='full-width' variant="outline-warning" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'>EDIT</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button className='full-width' variant="outline-danger" onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} size='sm'>DELETE</Button>
                                                    </Col>
                                                </Row>
                                            ]
                                        })
                                        } />
                                </div>
                                <DeleteModal modalText={"Delete State ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <WorkflowStateUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        workflowStateList: state.workflowReducer.workflowStateList,
        token: state.usersReducer.token,
        workflowdetails: state.workflowReducer.workflowTransitionDetailList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteState: (accessToken, id) => dispatch(removeWorkFlowState(accessToken, id)),
        fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
        createWorkflowState: (accessToken, requestBody, workflowId) => dispatch(createWorkflowState(accessToken, requestBody, workflowId)),
        getWorkflowData: (accessToken, Id) => dispatch(fetchWorkflowDetails(accessToken, Id)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
