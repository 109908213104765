import { FETCH_WORKFLOW_DETAIL_LIST, FETCH_WORKFLOW_LIST, FETCH_ASSIGNED_STATE, FETCH_WORKFLOW_STATE_LIST, FETCH_WORKFLOW_TRANSITION_LIST } from './actionTypes';
import { DEFAULT_DATA } from '../../lib/constants';

const initialState = {
    workflowList: DEFAULT_DATA,
    workflowStateList: DEFAULT_DATA,
    workflowTransitionList: DEFAULT_DATA,
    workflowTransitionDetailList: DEFAULT_DATA,
    assignedState: DEFAULT_DATA,
};

const workflowReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKFLOW_LIST:
            return {
                ...state,
                workflowList: action.payload
            };
        case FETCH_WORKFLOW_STATE_LIST:
            return {
                ...state,
                workflowStateList: action.payload
            };
        case FETCH_WORKFLOW_TRANSITION_LIST:
            return {
                ...state,
                workflowTransitionList: action.payload
            };
        case FETCH_WORKFLOW_DETAIL_LIST:
            return {
                ...state,
                workflowTransitionDetailList: action.payload
            };
        case FETCH_ASSIGNED_STATE:
            return {
                ...state,
                assignedState: action.payload
            };
        default:
            return state;
    }
};

export default workflowReducer;
