import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { workflowActionComment } from '../action';


class WorkflowActionCommentModal extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    handleCreateWorkflowCommentAction(e) {
        e.preventDefault();
        let requestBody = {
            "comment": this.state.comment,
            "form_data": parseInt(this.props.id),
            "transition": this.props.transitionId
        }
        if (requestBody["comment"].replace(/\s/g, '').length) {
            this.props.workflowActionComment(this.props.token.access, requestBody);
        }
        this.setState({ comment: '' })
        this.props.handleClose();
    }
    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title >Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form onSubmit={(e) => this.handleCreateWorkflowCommentAction(e)} className='font-medium'>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Add Comment</label>
                                <textarea className="form-control" onChange={event => { this.setState({ comment: event.target.value }); }}
                                    value={this.state.comment} id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <Form.Group className="mt-3 d-flex justify-content-end" >
                                <Button type='submit' variant="warning" size='sm' >ADD COMMENT</Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        workflowaction: state.formsReducer.workflowAction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        workflowActionComment: (accessToken, requestBody) => dispatch(workflowActionComment(accessToken, requestBody))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowActionCommentModal);