import React, { Component } from 'react'
import { Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { REQUEST_METHOD } from '../../../lib/constants';
import { fetchApi } from '../../../lib/utils';
import { updateLoader } from '../../common/action';

class Dropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            placeholder: "",
            required: false,
            borderShow: false,
            options: []
        }
    }

    componentDidMount() {
        if (this.props.fieldData.master_data_code !== 'string') {
            if (this.props.token === undefined) {
                this.props.setLoader(true);
                fetchApi(`/forms/template/${this.props.fieldData.master_data_code}/${this.props.ShareToken}/data/`,
                    null,
                    REQUEST_METHOD.GET,
                    (successResponse) => {
                        let data = []
                        successResponse.map((row) => {
                            return row.data.map((obj) => {
                                return data.push(obj.value)
                            })
                        })
                        this.props.setLoader(false);
                        this.setState({ options: data })
                    },
                    (failureResponse) => {
                        this.props.setLoader(false);
                    })

            }
            else {
                this.props.setLoader(true);
                fetchApi(`/forms/template/${this.props.fieldData.master_data_code}/data/`,
                    this.props.token.access,
                    REQUEST_METHOD.GET,
                    (successResponse) => {
                        let data = []
                        successResponse.data.map((row) => {
                            return row.data.map((obj) => {
                                return data.push(obj.value)
                            })
                        })
                        this.props.setLoader(false);
                        this.setState({ options: data })
                    })
            }
        }
        else if (this.props.fieldData.values) {
            this.props.setLoader(true);
            if (this.props.fieldData.values.includes(',')) {
                this.setState({ options: this.props.fieldData.values.split(',').map((item) => {
                        return item.trim()
                    }) })
                this.props.setLoader(false);
            }
            else {
                this.setState({ options: [this.props.fieldData.values] })
                this.props.setLoader(false);
            }
        }

    }


    render() {
        return (
            <Form.Select value={this.props.selected} required={this.props.required} disabled={this.props.editable} title={this.props.fieldData.tooltip} aria-label="Default select example"
                onChange={(event) => this.props.onChange(event.target.value)}>
                <option value={''}>-{this.props.fieldData.placeholder}-</option>
                {this.state.options.length > 0 && this.state.options.map((row, index) => {
                    return (
                        <option value={row} key={index}>{row}</option>
                    )
                })}
            </Form.Select>
        )
    }
}


const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (status) => dispatch(updateLoader(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);