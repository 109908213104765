import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import Background from "../../../Images/green.webp";
import Toaster from '../../common/components/Toaster';
import Footer from '../../../layouts/Footer';
import { resetConformation, updateSharedData } from '../action';
import TooltipPositioned from '../../common/components/TooltipPositioned';


class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uidb64value:"",
            resetToken:"",
            password: "",
            confirmPasword: "",
            passwordValidation: false,
            passwordMatchValidation: false
        }
    }
     
    validatePassword(password) {
        let idealPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/;
        return idealPassword.test(password);
    }

    matchPassword(password, confirmedPassword) {
        return password === confirmedPassword
    }
    
    handleSubmitAction(event) {
        event.preventDefault()
        let requestBody = {
            "password": this.state.password,
            "token": this.state.resetToken,
            "uidb64": this.state.uidb64value,
        }
        if (this.state.passwordValidation === true && this.state.passwordMatchValidation === true) {
            this.props.resetConformation(requestBody)
            this.setState({
                password: "",
                confirmPasword: "",
            })
        }
        else {
            this.props.setNotification(true, 'Please enter valid credentials.', "danger")
        }
    }

        componentDidMount(){
            var location=window.location.href;
            var paramString = location.split('?')[1];
            var queryString = new URLSearchParams(paramString);
            for(let pair of queryString.entries()) {
                if(pair[0]==="uidb64"){
                  this.setState({uidb64value:pair[1]})
                }
                else if(pair[0]==="token"){
                    this.setState({resetToken:pair[1]})

                }
                
            };
        }
  
        
    render() {
        return (
            <div className='full-height' style={{ backgroundImage: `url(${Background})` }}>
                <Row style={{ position: "absolute", right: 0 }} >
                    <Col>
                        <Toaster />
                    </Col>
                </Row>
                <div className="main-content-container container-fluid full-margin-block" >
                    <h3 className='text-center fw-bold text-uppercase'>Reset Password</h3>
                    <Row className='d-flex justify-content-center'>
                        <Col md='4'>
                            <Card className='shadow p-3 mb-5 bg-white rounded' >
                                <Card.Body className='m-4'>
                                    <h3 className='text-center fw-normal'>Reset Password</h3>
                                    <Form onSubmit={(e) => { this.handleSubmitAction(e) }}>
                                    <Form.Group className="mb-2 font-medium" controlId="formBasicPassword">
                                            <Form.Label>Password </Form.Label>
                                            <Form.Control
                                                onBlur={(event) => {
                                                    if (this.validatePassword(event.target.value)) { this.setState({ passwordValidation: true }) }
                                                    else {
                                                        this.setState({ passwordValidation: false });
                                                    }
                                                }
                                                }
                                                type="password" placeholder="Password" required value={this.state.password}
                                                onChange={event => {
                                                    if (this.validatePassword(event.target.value)) { this.setState({ passwordValidation: true }) } else {
                                                        this.setState({ passwordValidation: false });
                                                    }; this.setState({ password: event.target.value });
                                                }} />
                                            {
                                                this.state.passwordValidation === false && this.state.password.length > 0 &&
                                                <span>
                                                    <span style={{
                                                        fontWeight: '500',
                                                        color: 'red',
                                                    }}> Password is not Strong Enough  </span>
                                                    <TooltipPositioned tooltipText={"Password must contain-"} tooltipTextArray={["At least 8 Characters", "At most 16 Characters", "At least one Uppercase character [A-Z]", "At least one Lowercase character [a-z]", "At least one Number [0-9]", "At least one Special character [!@#$%^&*]"]} />
                                                </span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3 font-medium" controlId="formBasicPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" onBlur={(event) => { if (this.state.passwordMatchValidation === true) { this.setState({ passwordMatchValidation: true }) } }} placeholder="Re-Enter Password" required value={this.state.confirmPasword}
                                                onChange={event => {
                                                    if (this.matchPassword(this.state.password, event.target.value) === true) { this.setState({ passwordMatchValidation: true }) }
                                                    else {
                                                        this.setState({ passwordMatchValidation: false })
                                                    }; this.setState({ confirmPasword: event.target.value });
                                                }} />
                                            {
                                                this.state.passwordMatchValidation === false && this.state.confirmPasword.length > 0 &&
                                                <span>
                                                    <span style={{
                                                        fontWeight: '500',
                                                        color: 'red',
                                                    }}>  Password confirmation doesn't match password  </span>
                                                    <TooltipPositioned tooltipText={"Passwords should match."} />
                                                </span>
                                            }
                                        </Form.Group>
                                        <Button variant="success" type="submit" className='full-width mt-3 mb-4'>
                                            RESET
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetConformation:(requestBody)=>dispatch(updateSharedData(requestBody))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
