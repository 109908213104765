export const FETCH_FORMS_LIST = 'views/forms/FETCH_FORMS_LIST';
export const FETCH_FORM_FIELDS = 'views/forms/FETCH_FORM_FIELDS';
export const FETCH_FORM_DATA = 'views/forms/FETCH_FORM_DATA';
export const FETCH_FORM = 'views/forms/FETCH_FORM';
export const FETCH_DATA = 'views/forms/FETCH_DATA';
export const FETCH_SHARED_DATA = 'views/forms/FETCH_SHARED_DATA';
export const FETCH_SHARED_FORM_FIELDS = 'views/forms/FETCH_SHARED_FORM_FIELDS';
export const FETCH_SHARED_FORM_DATA = 'views/forms/FETCH_SHARED_FORM_DATA';
export const FETCH_FORM_ENTRY_DATA = 'views/forms/FETCH_FORM_ENTRY_DATA';
export const FETCH_COMMENTS = "views/forms/FETCH_COMMENTS";
export const FETCH_PROJECT_FORM_DATA = "views/forms/FETCH_PROJECT_FORM_DATA";
export const FETCH_FORM_MEMBERS = "views/forms/FETCH_FORM_MEMBERS";
export const FETCH_MEMBERS = "views/forms/FETCH_MEMBERS";
export const FETCH_WORKFLOW_TEMPLATE = "views/forms/FETCH_WORKFLOW_TEMPLATE";
export const FETCH_UNIQUE_STATUS = "views/forms/FETCH_UNIQUE_STATUS";
export const FETCH_ALL_FORMS_LIST = 'FETCH_ALL_FORMS_LIST';
export const FETCH_USER_LEVEL = 'views/forms/FETCH_USER_LEVEL';
export const WORKFLOW_ACTION = 'views/forms/WORKFLOW_ACTION';
export const WORKFLOW_ACTION_LOG = 'views/forms/WORKFLOW_ACTION_LOG';
export const HIDDEN_FIELD_VALUE = 'views/forms/HIDDEN_FIELD_VALUE';
export const FETCH_ALL_DASHBOARD_LIST = 'views/forms/FETCH_ALL_DASHBOARD_LIST';
export const CUSTOM_FORM_LIST = 'views/forms/CUSTOM_FORM_LIST';
export const CURRENT_CUSTOM_FORM_DETAILS = 'views/forms/CURRENT_CUSTOM_FORM_DETAILS';

