import React, { useState } from 'react';
// import Slider from 'react-slick';
import "./benifit.css";
import EaseofUse from "../../../Images/Ease of Use.png"
import Customization from "../../../Images/Customization.png"
import IntegrationCapabilities from "../../../Images/Integration Capabilities.png"
import DataSecurity from "../../../Images/Data Security.png"
import AutomationandWorkflowManagement from "../../../Images/Automation and Workflow Management.png"
import RealTimeDataCollection from "../../../Images/Real-Time Data Collection.png"
import Scalability from "../../../Images/Scalability.png"
import MobileFriendlyDesign from "../../../Images/Mobile-Friendly Design.png"
import CostEffectiveSolution from "../../../Images/Cost-Effective Solution.png"

export default function Benifits() {
  const [benefitsactiveIndex, setbenefitsactiveIndex] = useState(0);

  const handleBenefitsBox = (index) => {
    setbenefitsactiveIndex(index);
  };
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     autoplay: true, 
    //     autoplaySpeed: 3000, 
    //     responsive: [
    //       {
    //         breakpoint: 992,
    //         settings: {
    //           slidesToShow: 2,
    //         }
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 1,
    //         }
    //       }
    //     ]
    //   };

  return (
    // <div className='container' style={{paddingLeft:"60px",paddingRight:"60px"}}>
    //    <div className="sec-title text-center">
    //           <h2>Benefits</h2>
             
    //         </div>
    // <Slider {...settings}>
    //   <div className='col-md-3'>
    //       <div className="Benefits">
    //         <div class="gradient">
    //           <img src={features_dmsintegration} />

    //         </div>
    //         <div class="theInfo">
    //           <h5 class="the-title"> Ease of Use</h5>
    //           <p class="the-text">Giga-Forms offers an intuitive, drag-and-drop form builder, making it easy to create and customize forms without any coding skills. This allows users to quickly set up forms tailored to their specific needs.
    //           </p>
    //         </div>

    //       </div>
    //   </div>
    //   <div className='col-md-3'>
    //   <div className="Benefits">
    //         <div class="gradient">
    //           <img src={features_dmsintegration} />

    //         </div>
    //         <div class="theInfo">
    //           <h5 class="the-title"> Customization</h5>
    //           <p class="the-text">With Giga-Forms, you can fully customize forms to match your brand's style and requirements. The platform offers a wide range of templates, themes, and design options, ensuring that your forms look professional and are aligned with your brand identity.
    //           </p>
    //         </div>

    //       </div>
    //   </div>
   
    // </Slider>
    // </div>
    <>
      <section className="benefit-section">
       
       <div className="overlay-benefits">

       </div>
       <div className="overlay-benefits-contents">
       <div >
               <div className={`benefits-contents${benefitsactiveIndex === 0 ? 'active' : ''}`}>
<img src={EaseofUse}/>
<div className="p-4">
<p class="the-text">Giga-Forms offers an intuitive, drag-and-drop form builder, making it easy to create and customize forms without any coding skills. This allows users to quickly set up forms tailored to their specific needs.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 1 ? 'active' : ''}`}>
<img src={Customization}/>
<div className="p-4">
<p class="the-text">With Giga-Forms, you can fully customize forms to match your brand's style and requirements. The platform offers a wide range of templates, themes, and design options, ensuring that your forms look professional and are aligned with your brand identity.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 2 ? 'active' : ''}`}>
<img src={IntegrationCapabilities}/>
<div className="p-4">
<p class="the-text">Giga-Forms seamlessly integrates with various third-party applications, such as payment gateways, CRM systems, and email marketing tools. This enables smooth workflows and efficient data management across different platforms.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 3 ? 'active' : ''}`}>
<img src={DataSecurity}/>
<div className="p-4">
<p class="the-text">Giga-Forms places a strong emphasis on data security, ensuring that all information collected through forms is protected with robust encryption. This is particularly important for handling sensitive data, such as in healthcare or financial transactions.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 4 ? 'active' : ''}`}>
<img src={AutomationandWorkflowManagement}/>
<div className="p-4">
<p class="the-text">The platform supports automation features like conditional logic, auto-responders, and notifications. This helps streamline processes by automatically routing form data, triggering actions, and keeping relevant stakeholders informed.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 5 ? 'active' : ''}`}>
<img src={RealTimeDataCollection}/>
<div className="p-4">
<p class="the-text">Giga-Forms allows for real-time data collection and analysis. Users can access form submissions instantly, monitor responses as they come in, and generate reports for deeper insights.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 6 ? 'active' : ''}`}>
<img src={Scalability}/>
<div className="p-4">
<p class="the-text">Whether you’re a small business or a large enterprise, Giga-Forms is designed to scale with your needs. The platform can handle high volumes of submissions and supports a wide variety of form types, from simple contact forms to complex multi-step surveys.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 7 ? 'active' : ''}`}>
<img src={MobileFriendlyDesign}/>
<div className="p-4">
<p class="the-text">Forms created with Giga-Forms are responsive and mobile-friendly, ensuring that they function well on any device. This is crucial for reaching users on smartphones and tablets, improving the accessibility and usability of your forms.
           </p>
</div>
               </div>
               <div className={`benefits-contents${benefitsactiveIndex === 8 ? 'active' : ''}`}>
<img src={CostEffectiveSolution}/>
<div className="p-4">
<p class="the-text">Giga-Forms provides a cost-effective solution for businesses looking to automate data collection and management. It eliminates the need for expensive custom development while offering robust features that cater to a wide range of industries.
           </p>
</div>
               </div>
             </div>
</div>
       <div className="container">
         <div className="row">
           <div className="col-md-7">

          
       <div className="sec-title text-left text-white">
           <h2 className="text-white">Benefits</h2>
         </div>
         <div class="solutions">
               <div class="row">
                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(0)}>Ease of Use</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(1)}>Customization</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white"onClick={() => handleBenefitsBox(2)}>Integration Capabilities</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white"onClick={() => handleBenefitsBox(3)}>Data Security</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(4)}>Automation and Workflow Management</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(5)}>Real-Time Data Collection</h5>

                       </div>

                     </div>
                   </div>
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(6)}>Scalability</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(7)}>Mobile-Friendly Design</h5>

                       </div>

                     </div>
                   </div>

                 
                   <div class="col-xs-6 col-sm-4">
                     <div class="aviation">


                       <div class="aviation__caption">
                         <h5 class="aviation__title font-hv text-white" onClick={() => handleBenefitsBox(8)}>Cost-Effective Solution</h5>

                       </div>

                     </div>
                   </div>

                                   </div>
             </div>
             </div>
             
         </div>
       </div>
     </section>
    </>
  );
}
