import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants";
import { FETCH_PROJECTS, FETCH_PROJECT_DETAILS, FETCH_PROJECT_FORMS, PROJECT_MEMBER_LIST } from "./actionTypes";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function fetchAllProjects(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_PROJECTS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchProjects(accessToken, searchQuery = '', pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/?page=${pageNumber}${searchQuery}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_PROJECTS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function createProject(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Project Created successfully", "success"));
                dispatch(fetchProjects(accessToken));
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function updateProject(accessToken, pk, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/${pk}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Project Updated Successfully', 'success'))
                dispatch(fetchProjects(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function fetchProjectDetails(accessToken, pk) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/${pk}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_PROJECT_DETAILS, payload: successResponse })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchProjectForms(accessToken, Id, pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/${Id}/template/?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_PROJECT_FORMS, payload: successResponse })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function deleteProject(accessToken, projectId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/${projectId}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Project deleted Successfully', 'danger'))
                dispatch(fetchProjects(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function deleteForm(accessToken, formId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/${formId}/delete/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Deleted Successfully', 'danger'));
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }, null, RESPONSE_TYPE.NULL
        )
    }
}
export function fetchProjectMembers(accessToken, project_id, searchQuery = '', pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/projects/${project_id}/user/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: PROJECT_MEMBER_LIST, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function addProjectMember(accessToken, requestBody, projectId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/templatepermissions/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "User Added successfully", "success"));
                dispatch(fetchProjectMembers(accessToken, projectId));
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function updateProjectMember(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/templatepermissions/${requestBody.ID}/`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch(setNotification(true, 'Member Updated Successfully', 'success'))
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function deleteProjectMember(accessToken, memberId, projectId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/common/templatepermissions/${memberId}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(fetchProjectMembers(accessToken, projectId));
                dispatch(setNotification(true, 'Removed Successfully', 'success'));
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }, null, RESPONSE_TYPE.NULL
        )
    }
}