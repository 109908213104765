import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';

class LoadingModal extends Component {
    render() {
        return (
            <Modal show={this.props.loading} onHide={() => { }} backdrop="static" keyboard={false} centered={true}>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Spinner animation="border" variant="warning" />
                            <p>Loading...</p>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);
