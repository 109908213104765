import React, { Component } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import { fetchAllTeamlist, fetchSelectTeamUser } from '../action';

class Notification extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allSelect: 0,
            optionId: "",
            selectedCheckbox: []
        }
    }
    componentDidMount() {
        this.props.getTeams(this.props.token.access);

    }
    componentDidUpdate(prevProps, preState) {
        if (this.props.selectedUsers.count != prevProps.selectedUsers.count) {
            var l = []
            for (var i = 0; i < this.props.selectedUsers.count; i++) {
                l.push(false)
            }
            this.setState({ selectedCheckbox: l })
        }
    }

    updateSelectedCheckbox(index, list, checked) {
        var l = list
        for (var i = 0; i < this.props.selectedUsers.count; i++) {
            if (i == index) {
                l[i] = checked
            }
        }
        this.setState({ selectedCheckbox: l })
    }


    handleSearchAction() {
        this.props.getSelectedUser(this.props.token.access, this.state.optionId);
    }
    render() {
        return (
            <div>
                <div className="main-content-container p-4 container-fluid" >
                    <Row>
                        <Col>
                            <p>Notification</p>
                            <Card>
                                <Card.Body className='font-medium'>
                                    <Form>
                                        <Row>
                                            <Col className='m-4'>
                                                <Form.Label>Select Team</Form.Label>
                                                <Form.Select size='sm' required onChange={(event) => this.setState({ optionId: event.target.value })} >
                                                    <option>-Select  Team-</option>
                                                    {this.props.teams.results && this.props.teams.results.length > 0 && this.props.teams.results.map((row, index) => {
                                                        return (
                                                            <option value={row.team.id} key={index} onChange={() => this.setState({ optionId: row.team.id })}>{row.team.name}</option>

                                                        )
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col className='m-5'>
                                                <Button onClick={() => this.handleSearchAction()} variant="outline-success" size='md'>SUBMIT</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <div className='mt-3 font-medium'>
                                        {
                                            this.props.selectedUsers.results && this.props.selectedUsers.results.length > 0 &&
                                            <DataTable
                                                totalItemsCount={this.props.selectedUsers.count}
                                                headers={[<Form.Check className='mt-1' defaultChecked={false} onChange={(event) => {
                                                    var l = []
                                                    for (var i = 0; i < this.props.selectedUsers.count; i++) {
                                                        l.push(event.target.checked)
                                                    }
                                                    this.setState({ selectedCheckbox: l })
                                                }}>
                                                </Form.Check>, `User Name`, `Email`, `Phone`,]}
                                                // onPageChange={(pageNumber) => this.props.fetchUserList(this.props.token.access, pageNumber, '')}
                                                body={this.props.selectedUsers.results.map((row, index) => {
                                                    return [
                                                        <Form.Check className='mt-1' checked={this.state.selectedCheckbox[index]} onChange={(event) =>
                                                            this.updateSelectedCheckbox(index, this.state.selectedCheckbox, event.target.checked)
                                                        }>
                                                        </Form.Check>,
                                                        row.user.name,
                                                        row.user.email,
                                                        row.user.phone,

                                                    ]
                                                })} />
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        teams: state.notificationReducer.teams,
        selectedUsers: state.notificationReducer.selectedUsers,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTeams: (accessToken, pageNumber) => dispatch(fetchAllTeamlist(accessToken, pageNumber)),
        getSelectedUser: (accessToken, Id) => dispatch(fetchSelectTeamUser(accessToken, Id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
