import React, { useState } from "react";
import Formcontactus from "../../../Images/contact-us.png";
import PhoneInput from "react-phone-input-2";
import { Form } from "react-bootstrap";

import "react-phone-input-2/lib/bootstrap.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";

 const ContactHome =()=>  {


   const[state,setState] = useState(
    {
      name: "",
      email: "",
      number: "",
      // subject: "",
      message: "",
      // Product_Name: "GIGAFORMS",
    }
   
    )
  

    const handleSubmitAction = async (e) => {
      e.preventDefault();
  
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "token 1f229780140f7fb:acf7716d7fee107");
      myHeaders.append("Content-Type", "application/json");
  
      const requestBody = JSON.stringify({
        status: "Lead",
        custom_product: "GigaForms",
        lead_name: state.name,
        email_id: state.email,
        mobile_no: state.number,
        source: "Website",
        custom_message: state.message,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
      };
  
      try {
        if (state.name !== "" && state.email !== "" && state.number !== "" && state.message !== "") {
          const response = await fetch("https://erp.epsumlabs.in/api/resource/Lead", requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          await response.text(); // Assuming the response is just text; adjust if it's JSON
          console.log("Form submitted successfully.");
  
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Response was Recorded Successfully",
            confirmButtonColor: "#11134c",
            confirmButtonText: "Ok",
          });
  
          // Clear the form fields
          setState({
            name: "",
            email: "",
            number: "",
            message: "",
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Warning",
            text: "Please fill all the input fields.",
            confirmButtonColor: "#11134c",
            confirmButtonText: "Ok",
          });
        }
      } catch (error) {
        console.error("Error:", error.message || error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was a problem submitting your form. Please try again later.",
          confirmButtonColor: "#11134c",
          confirmButtonText: "Ok",
        });
      }
    };
  
  
    return (
      <div>
    

    <section
          id="contact"
          className="contact"
          style={{ backgroundColor: "#fafbff" }}
        >
          <div className="container">
          <div className="sec-title text-center">
              <h2>Contact Us</h2>
            </div>
            <div className="row gy-4">
              <div className="col-lg-6">
                <img title="contactus" src={Formcontactus} alt="img" />
              </div>
              <div className="col-lg-6">
                <form
                  
                  className="php-email-form"
                >
                  <div className="row gy-4">
                    <div className="col-md-12">
                    <input
  type="text"
  name="name"
  className="form-control"
  style={{ padding: "10px 15px" }}
  placeholder="Your Name"
  required
  value={state.name}
  onChange={(event) => {
    setState({ ...state, name: event.target.value });
  }}
/>
                    </div>

                    <div className="col-md-12 ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        style={{ padding: "10px 15px" }}
                        required
                        value={state.email}
                        onChange={(event) => {
                          setState({  ...state,email: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <PhoneInput
                        containerStyle={{ width: "100%" }}
                        inputStyle={{ width: "100%", height: "44px" }}
                        
                        name="number"
                        country={"in"}
                        enableSearch={true}
                        value={state.number}
                        onChange={(event) => {
                          setState({  ...state,number: event });
                        }}
                      />
                    </div>
                    {/* <div className="col-md-12">
                      <Form.Group
                        controlId="subject"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Control
                          defaultValue={
                            this.state.partnerStatus == true ? "" : "Subject"
                          }
                          as="select"
                          required
                          name="subject"
                          onChange={(e)=>{this.setState({subject:e.target.value})}}
                          style={{ borderRadius: "0", fontSize: "14px" }}
                        >
                          <option value="">Subject</option>
                          <option value="Oppurtunity for partnership">Oppurtunity for partnership</option>
                          <option value="General Inquiry">
                            General Inquiry
                          </option>
                          <option value="Sales Inquiry">Sales Inquiry</option>
                          <option value="Technical Support">
                            Technical Support
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </div> */}
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        required
                        value={state.message}
                        onChange={(event) => {
                          setState({  ...state,message: event.target.value });
                        }}
                      ></textarea>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                      <button
                        type="submit"
                        onClick={(e) => {
                          handleSubmitAction(e);
                        }}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

     
      </div>
    );
  
}


export default ContactHome;