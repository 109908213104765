import { DEFAULT_DATA } from '../../lib/constants';
import { LOADING_START, LOADING_END, SET_NOTIFICATION, FETCH_DASHBOARD, USER_LIST, USER_BILLINH_LIST } from './actionTypes';

const initialState = {
    loading: false,
    notification: {
        show: false,
        text: '',
        color: 'white'
    },
    userList: DEFAULT_DATA,
    userbillingList: DEFAULT_DATA,
    dashboardDetails: []
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_START:
            return {
                ...state,
                loading: true
            };
        case LOADING_END:
            return {
                ...state,
                loading: false
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case FETCH_DASHBOARD:
            return {
                ...state,
                dashboardDetails: action.payload
            }
        case USER_LIST:
            return {
                ...state,
                userList: action.payload
            }
            case USER_BILLINH_LIST:
            return {
                ...state,
                userbillingList: action.payload
            }
        default:
            return state;
    }
};

export default commonReducer;
