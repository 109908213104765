import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants"
import { fetchApi } from "../../lib/utils"
import { setNotification } from "../common/action"
import { LOADING_END, LOADING_START } from "../common/actionTypes"
import { FETCH_WORKFLOW_DETAIL_LIST, FETCH_WORKFLOW_LIST, FETCH_ASSIGNED_STATE, FETCH_WORKFLOW_STATE_LIST, FETCH_WORKFLOW_TRANSITION_LIST } from "./actionTypes"

export function fetchWorkflowList(accessToken, searchQuery = '', pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/?page=${pageNumber}${searchQuery}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_WORKFLOW_LIST, payload: successResponse })

            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
export function fetchWorkflowDetails(accessToken, Id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/${Id}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_WORKFLOW_DETAIL_LIST, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
export function createWorkflow(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Workflow Created Successfully', 'success'))
                dispatch(fetchWorkflowList(accessToken))
            }, (failureResponse) => {
                console.log("fail",failureResponse)
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })

            },
            requestBody
        )
    }
}

export function updateWorkflow(accessToken, pk, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/${pk}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Workflow Updated Successfully', 'success'))
                dispatch(fetchWorkflowList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function fetchWorkflowStateList(accessToken, workflowId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/${workflowId}/state/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_WORKFLOW_STATE_LIST, payload: successResponse })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function createWorkflowState(accessToken, requestBody, workflowId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/state/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'State Created Successfully', 'success'))
                dispatch(fetchWorkflowStateList(accessToken, workflowId))
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function updateWorkflowState(accessToken, pk, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/state/${pk}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'State Updated Successfully', 'success'))
                dispatch(fetchWorkflowStateList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function fetchWorkflowTransitionList(accessToken, workflow_id, pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/${workflow_id}/transition?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_WORKFLOW_TRANSITION_LIST, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function createWorkflowTransition(accessToken, requestBody, workflow_id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/transition/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Transition Created Successfully', 'success'))
                dispatch(fetchWorkflowTransitionList(accessToken, workflow_id))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function updateWorkflowTransition(accessToken, pk, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/transition/${pk}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Transition Updated Successfully', 'success'))
                dispatch(fetchWorkflowTransitionList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function removeWorkFlowState(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/state/${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setNotification(true, 'State Deleted Successfully', 'danger'))
                dispatch(fetchWorkflowStateList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function deleteWorkFlow(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setNotification(true, 'Workflow Deleted Successfully', 'danger'))
                dispatch(fetchWorkflowList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function deleteWorkFlowTransition(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/transition/${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setNotification(true, 'Transition Deleted Successfully', 'danger'))
                dispatch(fetchWorkflowTransitionList(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function assignWorkflow(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/template/state/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Workflow Assigned Successfully', 'success'))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function fetchAssignedState(accessToken, templateId, userId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/workflow/state/member/${templateId}/${userId}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_ASSIGNED_STATE, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}