import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { REQUEST_METHOD } from '../../../../lib/constants';
import { fetchApi } from '../../../../lib/utils';
import { updateLoader } from '../../../common/action'

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        if (this.props.fieldData.master_data_code !== 'string') {
            this.props.setLoader(true);
            fetchApi(`/forms/template/${this.props.fieldData.master_data_code}/data/`,
                this.props.token.access,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    let data = []
                    successResponse.data.map((row) => {
                        return row.data.map((obj) => {
                            return data.push(obj.value)
                        })
                    })
                    this.props.setLoader(false);
                    this.setState({ options: data })
                })
        }

    }

    render() {
        return (
            <div title={this.props.title} >
                {this.state.options.map((row, index) => {
                    return (<Form.Check key={index} defaultChecked={this.props.value == row ? true : false} type={`radio`} name="group1" label={row} onClick={() => this.props.onChange(row)} />)
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (status) => dispatch(updateLoader(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
