import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { getResetPassword } from '../action';

class ForgotPasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            redirect_url:`${window.location.origin}${"/resetpassword"}`
        }
    }

    handleSubmitAction() {
        let requestBody= {
            "email": this.state.email,
            "redirect_url": this.state.redirect_url
        }
        this.props.getResetPassword(requestBody)
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Forgot Password ?</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form.Group className="mb-3 mt-3 font-medium" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" required
                            onChange={event => { this.setState({ email: event.target.value }); }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.handleSubmitAction()} type='submit' variant="success" size='sm' >Proceed</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getResetPassword: (requestBody) => dispatch(getResetPassword(requestBody))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
