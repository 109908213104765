import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, } from 'react-bootstrap';
import { fetchProjects, createProject, deleteProject } from '../action';
import { formateDate } from '../../../lib/utils';
import DataTable from '../../common/components/DataTable';
import ProjectUpdateModal from './ProjectUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';
import { Link } from 'react-router-dom';


class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            description: '',
            showDeleteModal: false,
            showUpdateModal: false,
            selectedData: {},
            search: ''
        }
    }

    componentDidMount() {
        this.props.getProjects(this.props.token.access);
    }

    handleSearchAction() {
        this.props.getProjects(this.props.token.access, `&search=${this.state.search}`);
    }

    deleteAction(event) {
        this.props.removeProject(this.props.token.access, event.id);
    }

    handleCreateAction(event) {
        event.preventDefault()
        let requestBody = {
            name: this.state.label,
            description: this.state.description
        }
        if (requestBody["name"].replace(/\s/g, '').length) {
            this.props.addProject(this.props.token.access, requestBody);
        }
        else {
            this.props.setNotification(true, "Please enter a valid Project Name", "danger")
        }
        this.setState({
            label: '',
            description: '',
            showUpdateModal: false,
            selectedData: {},
            search: ''
        })
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <p>Projects</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Project Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Template Name" required value={this.state.label}
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Description" value={this.state.description}
                                                onChange={event => { this.setState({ description: event.target.value }); }} />
                                        </Col>
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className='mt-5 d-flex justify-content-end'>
                                    <Col md='3' >
                                        <Form.Control size="sm" type="text" placeholder="Search Project" required value={this.state.search}
                                            onChange={(event) => { this.setState({ search: event.target.value }); }} />

                                    </Col>
                                    <Col md='1' className='d-flex align-items-end'>
                                        <Button onClick={() => this.handleSearchAction()} variant="success" size='sm'>Search</Button>
                                    </Col>
                                </Row>
                                <div className='mt-3 font-medium'  >
                                    {
                                        this.props.projects.data && this.props.projects.data.length > 0 &&
                                        <DataTable
                                            totalItemsCount={this.props.projects.count}
                                            headers={[`Project Name`, `Description`, `Created On`, `Last Modified`, `Action`]}
                                            onPageChange={(pageNumber) => this.props.getProjects(this.props.token.access, `&search=${this.state.search}`, pageNumber)}
                                            body={this.props.projects.data.map((row, index) => {
                                                return [
                                                    row.name,
                                                    row.description,
                                                    formateDate(row.date_added),
                                                    formateDate(row.date_updated),
                                                    <Row>
                                                        <Col sm='3'>
                                                            <Button className='full-width' variant="outline-warning" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'>EDIT</Button>
                                                        </Col>
                                                        <Col sm='3'>
                                                            <Link to={`/projects/${row.id}/view_data`} >
                                                                <Button className='full-width' variant="outline-success" size='sm'>VIEW</Button>
                                                            </Link>
                                                        </Col>
                                                        <Col sm='3'>
                                                            <Button className='full-width' variant="outline-danger" size='sm' onClick={() => this.setState({ showDeleteModal: true, selectedData: row })}>DELETE</Button>
                                                        </Col>
                                                        <Col sm='3'>
                                                            <Link to={`/project/${row.id}/members`} >
                                                                <Button className='full-width' variant="outline-primary" size='sm'>MEMBERS</Button>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                ]
                                            })
                                            } />
                                    }

                                </div>
                                <DeleteModal modalText={"Delete Project ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <ProjectUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projects: state.projectReducer.projects,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeProject: (accessToken, projectId) => dispatch(deleteProject(accessToken, projectId)),
        getProjects: (accessToken, searchQuery, pageNumber) => dispatch(fetchProjects(accessToken, searchQuery, pageNumber)),
        addProject: (accessToken, requestBody) => dispatch(createProject(accessToken, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
