import React, { Component } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
    Breadcrumbs as MUIBreadcrumbs,
    Link,
    Typography
} from "@material-ui/core";
import { redirect } from '../../../lib/utils';

export default class Breadcrumbs extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    handleBreadcumsPath = (path, disabledStrings) => {
        var pathnames = [...path]
        path.map((item, index) => {
            if (disabledStrings.includes(String(item))) {
                pathnames.splice(pathnames.indexOf(String(item)), 1)
            }
        })
        return pathnames
    }

    render() {
        var pathnames = this.props.name.location.pathname.split("/").filter(x => x);
        if (this.props.disabledStrings) {
            pathnames = this.handleBreadcumsPath(pathnames, this.props.disabledStrings)
        }
        return (
            <MUIBreadcrumbs aria-label="breadcrumb" style={{ courser: "pointer" }}>
                {
                    pathnames.length > 0 ? (
                        <Link onClick={() => this.props.name.history.push("/")}>Home</Link>
                    ) : (
                        <Typography> Home </Typography>
                    )
                }
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === pathnames.length - 1;
                    return isLast ? (
                        <Typography key={name}>{name}</Typography>
                    ) : (
                        <Link key={name} onClick={() => redirect(routeTo)}>
                            {name}
                        </Link>
                    );
                })}
            </MUIBreadcrumbs>
        );

    }
}

