import React, { Component } from 'react'
import Navbar from "./Navbr";
import Footer from "./lfooter";
import VideoModal from './videoModal';


export default class video extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      showVideoModal:false
    }
  }
  render() {
    return (
      <div>
        <Navbar/>
        <section id="hero" className="d-flex align-items-center">

    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Tutorials</h1>
          <h2>Get the most out of Giga-forms with these first steps and pro tips .</h2>
          <div>
            <a href="http://173.212.215.65/EForm/E-Form.html

" className="btn-get-started scrollto">Help Center</a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="https://pinegrow.com/wp-content/uploads/Code-collaboration.svg" className="img-fluid animated" alt="img"/>
        </div>
      </div>
    </div>

  </section>
  <div>
    <center><h2 style={{marginTop:"150px",lineHeightL:"1.2rem",color:"#808080",
    fontSize: "26px",
    fontWeight:"400"}}>First Steps</h2>
    <p style={{marginTop:"15px",lineHeightL:"1.2rem",color:"#808080",
    fontSize: "20px",
    fontWeight:"200"}}>Learn the basics and create your first  Giga-forms </p></center>
  </div>
  <div className='container'>
  <div className="row" >
  <div className="col-lg-3" style={{textAlign:"center",
    justifyContent:"center",
    alignItems: "center",
    display: "flex"}}>
  <div className="card shadow  bg-white rounded" style={{width:"18rem"}} onClick={() => this.setState({ showVideoModal: true,})}>
  <img className="card-img-top" src="https://pinegrow.com/wp-content/uploads/Code-collaboration.svg" alt="Card image cap"/>
  <hr></hr>
  <div className="card-body">
   <center> <p className="card-text">Some quick example text</p></center>
  </div>
</div>
  </div>
  <div className="col-lg-3" style={{textAlign:"center",
    justifyContent:"center",
    alignItems: "center",
    display: "flex"}}>
  <div className="card shadow  bg-white rounded" style={{width:"18rem"}} onClick={console.log("hiii this is clickable")}>
  <img className="card-img-top" src="https://pinegrow.com/wp-content/uploads/Code-collaboration.svg" alt="Card image cap"/>
  <hr></hr>
  <div className="card-body">
   <center> <p className="card-text">Some quick example text</p></center>
  </div>
</div>
  </div>
  <div className="col-lg-3" style={{textAlign:"center",
    justifyContent:"center",
    alignItems: "center",
    display: "flex"}}>
  <div className="card shadow  bg-white rounded" style={{width:"18rem"}} onClick={console.log("hiii this is clickable")}>
  <img className="card-img-top" src="https://pinegrow.com/wp-content/uploads/Code-collaboration.svg" alt="Card image cap"/>
  <hr></hr>
  <div className="card-body">
   <center> <p className="card-text">Some quick example text</p></center>
  </div>
</div>
  </div>
  <div className="col-lg-3" style={{textAlign:"center",
    justifyContent:"center",
    alignItems: "center",
    display: "flex"}}>
  <div className="card shadow  bg-white rounded" style={{width:"18rem"}} onClick={console.log("hiii this is clickable")}>
  <img className="card-img-top" src="https://pinegrow.com/wp-content/uploads/Code-collaboration.svg" alt="Card image cap"/>
  <hr></hr>
  <div className="card-body">
   <center> <p className="card-text">Some quick example text</p></center>
  </div>
</div>
  </div>
</div>
<VideoModal showModal={this.state.showVideoModal} handleClose={() => this.setState({ showVideoModal: false })} />
  </div>
  <Footer/>
      </div>
    )
  }
}
