import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { fetchFormMembers } from '../../forms/action';
import { addTeamMember } from '../action';

class AddTeamMemberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            teamId: this.props.teamId,
            formId: this.props.formId,
            teamLead: "0"
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.formId !== this.props.formId) {
            this.props.getFormUserList(this.props.token.access, this.props.formId)
        }
    }

    handleAdd = () => {
        var requestBody = {
            "user": this.state.user,
            "team": this.props.teamId,
            "team_lead": this.state.teamLead
        }
        this.props.addTeamMember(this.props.token.access, requestBody, this.props.teamId)
        this.setState({
            user:'',
            teamId: '',
            formId: '',
            teamLead: "0"
        })
        this.props.handleClose();
    }

    render() {

        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {
                        this.props.formId && this.props.formMembers && this.props.formMembers.results && this.props.formMembers.results.length > 0 &&
                        <div>
                            <Form.Select title={'Select Member'} value={this.state.user} aria-label="Select Member" onChange={(data) => {this.setState({ user: data.target.value })}} 
                            ><option value="" disabled selected>Please Select Member</option>
                                {this.props.formMembers.results.map((row, index) => {
                                    return (
                                        <option value={row.user.id} key={index}>{row.user.name}</option>
                                    )
                                })}
                            </Form.Select>
                            <Form.Check className='mt-3' defaultChecked={false} label={"Team Lead"} onChange={(event)=>{if(event.target.checked){this.setState({teamLead: "1"})} else{this.setState({teamLead: "0"})}}}>
                            </Form.Check>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button onClick={() => { this.handleAdd(); }} type='submit' variant="success" size='sm' >ADD</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        formMembers: state.formsReducer.formMembers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFormUserList: (accessToken, formId) => dispatch((fetchFormMembers(accessToken, formId))),
        addTeamMember: (accessToken, requestBody, teamId) => dispatch((addTeamMember(accessToken, requestBody, teamId)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamMemberModal);
