import React, { Component } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkForUndoWorkflowTemplate, formateDate, validateWorkflowList } from '../../../lib/utils';
import DataTable from '../../common/components/DataTable';
import { fetchWorkflowList } from '../../workflows/action';
import { AddWorkflow, fetchWorkFlowTemplate, removeWorkFlowTemplate } from '../action';
import formsReducer from '../reducer';


class WorkflowTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',

        }
    }

    componentDidMount() {
        this.props.fetchWorkflowList(this.props.token.access);
        this.props.fetchWorkFlowTemplate(this.props.token.access)
    }

    handleSearchAction() {
        this.props.fetchWorkflowList(this.props.token.access, `&search=${this.state.search}`);
    }
    handleAddWorkflow = (row) => {
        let requestBody = {
            "description": row.description,
            "workflow": row.id,
            "template": this.props.params.id,
        }

        this.props.AddedWorkflow(this.props.token.access, requestBody);
    }
    handleRemoveWorkflow = (row) => {
        var workflowTemplateObj = checkForUndoWorkflowTemplate(this.props.workflowtemplatelist.results, row.id)

        this.props.removeWorkFlowTemplate(this.props.token.access, workflowTemplateObj.id)

    }

    render() {
        var validatedWorkflowList = []
        if (this.props.workflowList.results.length > 0) {
            validatedWorkflowList = validateWorkflowList(this.props.workflowList.results, this.props.workflowtemplatelist.results)
        }
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <p>Workflow</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Row className='mt-2 d-flex justify-content-end'>
                                    <Col md='10' >
                                        <Form.Control size="md" type="text" placeholder="Seach" required value={this.state.search}
                                            onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='2' className='d-flex align-items-end'>
                                        <Button onClick={() => this.handleSearchAction()} variant="success" size='md' style={{ width: "150px" }}>Search</Button>
                                    </Col>
                                </Row>

                                <div className='mt-3 font-medium'  >
                                    <DataTable
                                        totalItemsCount={this.props.workflowList.count}
                                        onPageChange={(pageNumber) => this.props.fetchWorkflowList(this.props.token.access, `&search=${this.state.search}`, pageNumber)}
                                        headers={[`Workflow Name`, `Description`, `Created On`, `Last Modified`, `Action`]}
                                        body={validatedWorkflowList.length > 0 && validatedWorkflowList.map((row, index) => {
                                            return [
                                                row.label,
                                                row.description,
                                                formateDate(row.date_added),
                                                formateDate(row.date_updated),
                                                <Row>
                                                    <Col sm='12'>
                                                        <Button className='full-width' variant={row.status == 'TRUE' ? "outline-warning" : 'outline-primary'} onClick={() => { row.status == 'TRUE' ? this.handleRemoveWorkflow(row) : this.handleAddWorkflow(row) }} size='sm'>{row.status == 'TRUE' ? 'UNDO' : 'ADD'}</Button>
                                                    </Col>
                                                </Row>
                                            ]
                                        })
                                        } />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div >
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        workflowList: state.workflowReducer.workflowList,
        workflowtemplatelist: state.formsReducer.workflowtemplatelist
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: (accessToken, searchQuery, pageNumber) => dispatch(fetchWorkflowList(accessToken, searchQuery, pageNumber)),
        AddedWorkflow: (accessToken, requestBody) => dispatch(AddWorkflow(accessToken, requestBody)),
        fetchWorkFlowTemplate: (accessToken) => dispatch(fetchWorkFlowTemplate(accessToken)),
        removeWorkFlowTemplate: (accessToken, id) => dispatch(removeWorkFlowTemplate(accessToken, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTemplates);
