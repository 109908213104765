import React, { Component } from 'react'
import CustomFormDashboard from './CustomFormDashboard'
import CustomNavbar from '../common/CustomNavbar'

export default class CustomForms extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggleClass: ""
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <CustomNavbar />
                <div >

                    <div  >
                        <CustomFormDashboard id={this.props.params && this.props.params.id} />
                    </div>
                </div>
            </div>
        )
    }
}
