import React, { Component } from 'react'
import Footer from "./lfooter";
import Navbar from "./Navbr";
import challanges from "../../../Images/challanges.webp";
import LT from "../../../Images/LT.png";
import benifits from "../../../Images/benefits.png";
import banner1 from "../../../Images/banner1.jpg";

export default class casestudy extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <section>
        <div className="sec-title text-center">
                            <span className="title">Streamlining College Admission Process with GigaForms</span>
                        </div>
                        
                     <div className="container" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                            <p style={{fontWeight:"600"}}>A renowned college implemented GigaForms to enhance their admission process. </p>
                            <p className='text-right' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>This part to show on the landing page when someone hovers the cursor over the case study. Once someone clicks, the below case study should be showing.</p>
                        </div>
                    </div>
                    <div className='col-md-6' data-aos="zoom-in-left">
                        <img src={banner1}style={{height:"250px",width:"800px"}} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>

                    <div className="container" style={{marginTop:"20px"}}>
                      <p style={{textAlign:"justify"}}>The college admission process is a crucial and complex task that requires efficient management and streamlined procedures to ensure a seamless experience for both applicants and administrators. The traditional paper-based application system resulted in time-consuming manual data entry, potential data loss, and a lack of transparency throughout the process. To address these issues, the institution sought a technology-driven solution and decided to integrate GigaForms into its admission process.</p>
                    </div>
                   
                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Challenges:</p>
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row' >
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Streamline the admission process:</b> Simplify and automate the creation, submission, and review of admission forms to reduce administrative burden and save time for applicants and admission staff.</li>
                                <li style={{textAlign:'justify'}}><b>Enhance data accuracy and security:</b> Ensure the integrity and security of applicant data by eliminating manual data entry and introducing encrypted online forms.</li>
                                <li style={{textAlign:'justify'}}><b>Improve applicant experience:</b> Provide a user-friendly platform that allows applicants to conveniently complete and submit admission forms from any location at any time..</li>
                                <li style={{textAlign:'justify'}}><b>Facilitate efficient review and decision-making:</b> Enable admission staff to access and review applications easily, facilitating informed decision-making during the selection process.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left">
                        <img src={challanges} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Solution:</p>
                    
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                <div className='col-md-4' data-aos="zoom-in-left">
                        <img src={LT} alt='img'></img>
                        
                    </div>
                    <div className='col-md-8' data-aos="zoom-in-right">
                        <div >

                            <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Form Creation:</b> GigaForms enabled the college's admission team to create custom online forms effortlessly. The user-friendly interface allowed them to design forms ranging from simple personal information forms to complex registration forms without requiring any coding knowledge.</li>
                                <li style={{textAlign:'justify'}}><b>Seamless Online Submission:</b> Applicants were provided with a unique link to access and complete their admission forms online. GigaForms ensured a smooth and intuitive user experience, allowing applicants to save their progress and return to complete the form at a later time if needed.</li>
                                <li style={{textAlign:'justify'}}><b>Automated Data Collection and Validation:</b> GigaForms eliminated the need for manual data entry. Upon submission, the tool automatically collected and validated applicant data, reducing the chances of errors and ensuring accurate information.</li>
                                <li style={{textAlign:'justify'}}><b>Centralized Application Database:</b> All submitted forms were securely stored in a centralized database, accessible to authorized admission staff. GigaForms' robust data management system facilitated efficient search and retrieval of application records.</li>
                                <li style={{textAlign:'justify'}}><b>Real-time Tracking and Notifications:</b>GigaForms provided applicants with real-time updates regarding the status of their application, ensuring transparency and reducing anxiety. Automated notifications were sent to applicants upon successful submission, document verification, and admission decision.</li>
                            </ol>
                            
                        </div>
                    </div>
                
                    </div>
                
                    
                    </div>

                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Results and Benefits:</p>
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Time and Cost Savings:</b> GigaForms significantly reduced administrative workload by eliminating manual data entry, resulting in substantial time and cost savings for both applicants and admission staff.</li>
                                <li style={{textAlign:'justify'}}><b>Enhanced Data Accuracy and Security:</b> The online form submission process eliminated data entry errors and improved data security through encrypted transmissions and secure storage.</li>
                                <li style={{textAlign:'justify'}}><b>Improved Applicant Experience:</b> Applicants appreciated the convenience of completing admission forms online at their own pace, from anywhere, and receiving timely updates on their application status.</li>
                                <li style={{textAlign:'justify'}}><b>Streamlined Selection Process:</b> The centralized database and easy accessibility of application records enabled admission staff to efficiently review and evaluate applications, leading to more informed and prompt decision-making.</li>
                                
                            </ol>
                            
                            
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left">
                        <img src={benifits} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>
                    <div className="container" style={{marginTop:"10px"}}>
                      <p><b>Conclusion:</b></p>
                      <p style={{textAlign:"justify"}}>By integrating GigaForms into the admission process, the college successfully streamlined and enhanced the efficiency of its admission procedures. The tool's user-friendly interface, automated data collection, and real-time tracking significantly improved the applicant experience, while the centralized database and robust management features facilitated effective decision-making for the admission staff. GigaForms proved to be an invaluable tool in transforming the traditional admission process, ensuring transparency, accuracy, and efficiency.</p>
                    </div>
        </section>
        <Footer />
      </div>
    )
  }
}
