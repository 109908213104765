import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipPositioned(props) {
  return (
    <>
      {['right'].map((right) => (
        <OverlayTrigger
          key={right}
          placement={right}
          overlay={
            <Tooltip id={`tooltip-${right}`}>
              {
                <>
                  <p>{props.tooltipText}</p>
                  {
                    props.tooltipTextArray && props.tooltipTextArray.length > 0 &&
                    <ol>{
                      props.tooltipTextArray.map((ele, idx) =>
                        <li style={{textAlign: "left"}} key={idx}>{ele}</li>
                      )
                    }
                    </ol>
                  }
                </>
              }
            </Tooltip>
          }
        >
          <i className="fa fa-info-circle" style={{ color: "black" }}></i>
        </OverlayTrigger>
      ))}
    </>
  );
}

export default TooltipPositioned;