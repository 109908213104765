import React from "react";
import { useState, useEffect } from "react";

function NameInitialPic(props) {

  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (props.name !== undefined) {
      let nameArr = props.name.split(" ");
      if (nameArr.length > 1) {
        setInitials(nameArr[0].charAt(0) + nameArr[nameArr.length - 1].charAt(0));
      }
      else {
        setInitials(nameArr[0].charAt(0))
      }
    }
  })

  let fontProperties = {
    fontSize: props.fontSize,
    color: 'black'
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: '#66d4b3',
        position: "relative",
        borderRadius: '50%',
        cursor: "pointer"
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          textAlign: "center",
          position: "absolute",
          fontFamily: "Monospace",
          top: '10%',
        }}
      >
        <div style={fontProperties}>{initials}</div>
      </div>
    </div>
  );
}

export default NameInitialPic; 