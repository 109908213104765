import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image, Modal, Button } from 'react-bootstrap';
import { REQUEST_METHOD, RESPONSE_TYPE } from '../../../../lib/constants';
import { fetchApi } from '../../../../lib/utils';
import PDF_ICON from '../../../../Images/pdf-icon.png';
import EXCEL_ICON from '../../../../Images/excel-icon.png';
import { setNotification } from '../../../common/action';

class FileTypeFieldHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            showModal: false
        }
    }

    componentDidMount = () => {
        let requestUrl = `/fileupload/files/${this.props.id}/`;
        let accessToken = null;
        if (['', null, undefined].includes(this.props.sharedToken)) {
            accessToken = this.props.token.access;
        }
        else {
            accessToken = this.props.sharedToken
        }
        if (!['', null, undefined].includes(this.props.id))
            fetchApi(requestUrl,
                accessToken,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    this.setState({ data: successResponse })
                },
                (failureResponse) => {
                    this.props.showNotification(true, failureResponse.statusText, 'danger')
                }
            )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            let requestUrl = `/fileupload/files/${this.props.id}/`;
            let accessToken = null;
            if (['', null, undefined].includes(this.props.sharedToken)) {
                accessToken = this.props.token.access;
            }
            else {
                accessToken = this.props.sharedToken
            }
            if (!['', null, undefined].includes(this.props.id))
                fetchApi(requestUrl,
                    accessToken,
                    REQUEST_METHOD.GET,
                    (successResponse) => {
                        this.setState({ data: successResponse })
                    },
                    (failureResponse) => {
                        this.props.showNotification(true, failureResponse.statusText, 'danger')
                    }
                )
        }
    }


    downloadFile() {
        let requestUrl = `/fileupload/files/${this.props.id}/download/`;
        let accessToken = null
        if (this.props.sharedToken === null) {
            accessToken = this.props.token.access;
        } else {
            requestUrl = `${requestUrl}${this.props.sharedToken}/`;
        }
        this.props.showNotification(true, `download will start automatically in 30 sec`, 'success')
        fetchApi(
            requestUrl,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                let blobResponse = URL.createObjectURL(successResponse);
                const downloadLink = document.createElement("a");
                downloadLink.download = this.state.data.name;
                downloadLink.href = blobResponse;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            },
            (failureResponse) => {
                this.props.showNotification(true, failureResponse.statusText, 'danger')
            }, null, RESPONSE_TYPE.BLOB
        )
    }

    returnFilePath(fileType) {
        fileType = fileType.toLocaleLowerCase()
        switch (fileType) {
            case `xlsx`:
                return EXCEL_ICON;
            case `pdf`:
                return PDF_ICON
            default:
                return this.state.data.file
        }
    }


    render() {
        return (
            <>
                {this.state.data.file && <Image src={this.returnFilePath(this.state.data.type ?? '')} title={this.state.data.name} alt={this.state.data.name} width={150} height={150} onClick={() => this.setState({ showModal: true })} />}
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} backdrop="static" keyboard={false} centered={true} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title >{this.state.data.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <center><Image src={this.returnFilePath(this.state.data.type ?? '')} title={this.state.data.name} alt={this.state.data.name} style={{ height: "200px" }} /></center>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-success' onClick={() => this.downloadFile()}>DOWNLOAD</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileTypeFieldHandler);
