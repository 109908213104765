import { VALIDATOR_VALUE_MAPPING } from "./constants";

export const isFuture = (date) => {
    var date = new Date(date);
    return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
};


export function validator(validationObj, checkValue) {
    try {
        switch (validationObj.type) {
            case VALIDATOR_VALUE_MAPPING.ALPHABET:
                return /^[a-zA-Z ]*$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.NUMBER:
                return /^\d+$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.DECIMAL_NUMBER:
                return /^\d+(\.\d)?\d*$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.ALPHANUMERIC:
                return /^[A-Za-z0-9 ]+$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.PERCENTAGE:
                return /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.EMAIL:
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(checkValue);
            case VALIDATOR_VALUE_MAPPING.FUTURE_DATE:
                return isFuture(checkValue);
            case VALIDATOR_VALUE_MAPPING.PAST_DATE:
                return !isFuture(checkValue);
            case VALIDATOR_VALUE_MAPPING.TODAY:
                {
                    var currentTime = new Date().toISOString().slice(0, 10);
                    return currentTime == checkValue;
                }
            default:
                return true;
        }
    }
    catch (error) {
        console.log(error, validationObj);
        return true;
    }
}
