import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
export default class Example extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data2: ""
    }
  }
  componentDidMount() {
    this.convertArray()
  }
  convertArray = () => {
    var data = []
    data.push(this.props.dataDetails[0], this.props.dataDetails[1], this.props.dataDetails[2], this.props.dataDetails[4])
    this.setState({ data2: data })
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={300}
          height={200}
          data={this.state.data2}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" stackId="a" fill="#8884d8" />
          <Bar dataKey="uses" stackId="a" fill="#82ca9d" />
          {/* stackId="b" */}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
