import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { redirect } from '../../../lib/utils'
import "./notification.css"
export default class Sucessful extends Component {
  render() {
    return (
      <div style={{justifyContent:"center",alignItems:"center",display:"flex",textAlign:"center",height:window.innerHeight, backgroundImage: 'linear-gradient(45deg, #019871, #a0ebcf)'}}>
            <div class="wrapperAlert">

<div class="contentAlert">

  <div class="topHalf">

    <center><p>
    <i class="fa fa-times-circle-o" aria-hidden="true" style={{fontSize:"100px",color:"red"}}></i>
        </p></center>
    <center><h1>Payment Failed</h1></center>
    <br/>
    <cenetr><Button variant='success' style={{zIndex:1}} onClick={()=>{ redirect('/subscription')}}>Try Again</Button></cenetr>

   <ul class="bg-bubbles">
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
   </ul>
  </div>
</div>        

</div>
      </div>
    )
  }
}
