import React, { Component } from "react";
import { checkLogin } from "../../../lib/utils";
import { connect } from "react-redux";
import { fetchContact } from "../../common/action";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Navbar from "./Navbr";
import Footer from "./lfooter";
import LogoSilider from "./LogoSlider";
import { Link } from "react-router-dom";
import {Button,Card,FloatingLabel,InputGroup,Modal} from "react-bootstrap";
import Cookies from "js-cookie";
import Aos from "aos";
import { Form } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import Applicationarea from "../../common/components/applicationarea";
import Benifits from "./benifits";
import gigaformbanner from "../../../Images/home-banner.jpg"
import gigaformContact from "../../../Images/gigaforms-side-banner.jpg"
import features_dragdrop from "../../../Images/features-drag-drop.jpg"
import features_cusomization from "../../../Images/features-cusomization.jpg"
import features_conditional from "../../../Images/features-conditional-logic.jpg"
import features_dmsintegration from "../../../Images/features-dms-integration.jpg"

import happycustomers from "../../../Images/happy-customers.jpg"
import countriesindia from "../../../Images/countries-india.jpg"
import yearbusiness from "../../../Images/year-of-business.jpg"
import satisfactionrate from "../../../Images/satisfaction-rate.jpg"
import Swal from "sweetalert2";
import ContactHome from "./ContactHome";
class LandingPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      number: "",
      subject: "",
      message: "",
      Product_Name: "GIGAFORMS",
      cookies: false,
      accepted: false,
      Customise: false,
      card1: false,
      card2: false,
      card3: false,
      card4: false,
      show: false,
      isSticky: false,
      demoname: "",
      demoemail: "",
      demonumber: "",
      demomsg: "",
      
      // benefitsactiveIndex:0
    };

    this.featureSectionRef = React.createRef();
  }
  // handleBenefitsBox = (index) => {
  //   this.setState({ benefitsactiveIndex: index });
  // };

  scrollToTop = () => {
    scroll.scrollToTop();
  };
  componentDidMount() {
    if (this.featureSectionRef.current) {
      this.featureSectionRef.current.addEventListener('wheel', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.featureSectionRef.current) {
      this.featureSectionRef.current.removeEventListener('wheel', this.handleScroll);
    }
  }
  handleScroll = (event) => {
    const { current } = this.featureSectionRef;
    const scrollTop = current.scrollTop;
    const scrollHeight = current.scrollHeight;
    const height = current.clientHeight;
    const deltaY = event.deltaY;

    if ((deltaY < 0 && scrollTop === 0) || (deltaY > 0 && scrollTop + height === scrollHeight)) {
      event.preventDefault();
    }
  }
  componentDidMount() {
    Cookies.get("cookie_consent") == undefined ? this.setState({ cookies: true }) : this.setState({ cookies: false })
    Aos.init();
    checkLogin("landingPage", this.props.navigate);
    if (Cookies.get("cookie_consent")) {
      this.setState({ accepted: false });
    }
    this.showCookies();
  }
  showCookies = () => {
    // setTimeout(() => {
    //   this.setState({ cookies: true });
    // }, 5000);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    localStorage.setItem("latitude", latitude);
    localStorage.setItem("longitude", longitude);
  };
  handleSubmitAction(e) {
    const { name, email, number, message, subject, Product_Name } = this.state;
    e.preventDefault();
    let requestBody = {
      data: [
        {
          value: name,
          field: 219,
        },
        {
          value: email,
          field: 220,
        },
        {
          value: number,
          field: 221,
        },
        {
          value: subject,
          field: 222,
        },

        {
          value: message,
          field: 224,
        },
        {
          value: Product_Name,
          field: 223,
        },
      ],
    };
    if (name !== "" && email !== "" && number !== "" && message !== "") {
      this.props.storeData("7177a71d-ca83-4685-9631-0c820ffa45ed", requestBody);
      this.setState({
        name: "",
        email: "",
        number: "",
        subject: "",
        message: "",
        Product_Name: "",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all the fields",
        confirmButtonColor: "#11134c",
        confirmButtonText: "Ok",
        allowOutsideClick: false,

      })
    }
  }

 async handledemoSubmit(e) {
    e.preventDefault();
    let requestBody = {
      data: [
        {
          value: this.state.demoname,
          field: 219,
        },
        {
          value: this.state.demoemail,
          field: 220,
        },
        {
          value: this.state.demonumber,
          field: 221,
        },
        {
          value: "Request For Demo",
          field: 222,
        },

        {
          value: this.state.demomsg,
          field: 224,
        },
        {
          value: this.state.Product_Name,
          field: 223,
        },
      ],
    };
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "token 1f229780140f7fb:acf7716d7fee107");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
      status:"Lead",
      custom_product:"GigaForms",
      lead_name:this.state.demoname,
      email_id:this.state.demoemail,
      mobile_no:this.state.demonumber,
      source:"Website",
      custom_message:this.state.demomsg
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestData,
      redirect: "follow"
    };

    try {
    if (this.state.demoname !== "" && this.state.demomsg !== "" && this.state.demoemail !== "" && this.state.demonumber !== "") {
      // this.props.storeData("7177a71d-ca83-4685-9631-0c820ffa45ed", requestBody);
      const response = await fetch("https://erp.epsumlabs.in/api/resource/Lead", requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.text();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your Response was Recorded Successfully",
        confirmButtonColor: "#11134c",
        confirmButtonText: "Ok",
      });
      this.setState({
        demoname: "",
        demoemail: "",
        demomsg: "",
        demonumber: "",
        show: false
      });

    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please fill all the fields",
        confirmButtonColor: "#11134c",
        confirmButtonText: "Ok",
        allowOutsideClick: false,

      })
    }}
    catch (error) {
      console.error("Error:", error.message || error);
    }
  }

  handleAccept = () => {
    // When the user accepts the cookie policy, set a cookie with the name "cookie_consent" and the value "true"
    Cookies.set("cookie_consent", "true", { expires: 365 });
    this.setState({ cookies: false });
  };
  // componentDidMount() {

  // }
  render() {
    console.log(this.state.subject);
    return (
      <div>
        {/* <Applicationarea/> */}
        <div className="scroll-to-top" onClick={this.scrollToTop}>
          <i className="fa fa-arrow-up"></i>
        </div>
        {this.state.cookies == true ? (
          <div className="d-flex justify-content-center">
          <Card
            className="bottom-0 wow fadeInUp"
            style={{
              width: this.state.Customise == true ? "50%" : "80%",
             bottom:0,
             top:this.state.Customise == true ? "50%" :"auto",
             left:this.state.Customise == true ? "50%" :"auto",
             transform: this.state.Customise == true ? "translate(-50%, -50%)" :"",
              // border: "1px solid ",
              height: this.state.Customise == true ? "400px" : "fit-content",
              position: "fixed",
              zIndex: "10",
              backgroundColor: "#fff",
              margin:0,
              marginBottom:"5px",
              padding:this.state.Customise == true ? "30px 60px" :"10px 100px",
              overflow:"auto"
              
            }}
            data-aos="fade-up-right"
          >
            {this.state.Customise == false ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    position:"absolute",
                    right:"0%",
                    top:"0"
                  }}
                >
                  {/* <h5 className="m-3 " style={{ fontSize: "1vw" }}>
                    COOKIE USAGE
                  </h5> */}
                  <span
                    className="m-2 p-2"
                    style={{ color: "white" ,cursor:"pointer"}}
                    onClick={() => {
                      this.setState({ cookies: false });
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true" style={{color:"black",fontSize:"25px"}}></i>
                  </span>
                </div>
                <div className="d-flex align-items-center">
                <p className=" m-2" style={{fontSize:"14px"}}>
                  We use cookies (including third-party cookies), to provide you
                  with the best possible online experience and to tailor content
                  to your interests. By clicking on "Accept all" you agree to
                  the further processing of the collected and read personal
                  data.
                </p>
                <Button
                className="mb-2"
                variant="light"
                size="md"
                style={{background: "#fd6517",
                  border: "0",
                  padding: "6px 30px",
                  color: "#fff",
                  marginLeft:"12px",
                  transition: "0.4s",
                  borderRadius: "4px" }}
                onClick={this.handleAccept}
              >
                Accept
              </Button>
              <Button
                className="mb-2"
                variant="light"
                size="md"
                style={{
                  border: "1px solid #fd6517",
                 marginLeft:"30px",
                  transition: "0.4s",
                  width:"300px",
                  borderRadius: "4px" }}
                  onClick={() => { this.setState({ Customise: true }) }}
              >
                Cookies Setting
              </Button>
              {/* <Button className='mb-2' variant="outline-light" size='md' style={{ width: "16vw" }} onClick={() => { this.setState({ Customise: true }) }}>Customise</Button> */}
                </div>
                {/* <p className=" m-2" style={{ fontSize: "12px" }}>
                  {" "}
                  For detailed information on the use and management of cookies,
                  please click on "Customise". By clicking on "Reject", you
                  reject the use of cookies that require consent.
                </p> */}
              </>
            ) : (
              <>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 className="mb-4">Manage Cookies</h3>
                    <p
                      className="m-2"
                      style={{position:"relative",}}
                      onClick={() => {
                        this.setState({ cookies: false });
                      }}
                    >
                      <i class="fa fa-times text-white" aria-hidden="true" style={{padding:"4px 6px",background:"#fd6517",borderRadius:"50%",cursor:"pointer"}}></i>
                    </p>
                  </div>
                <div>
                <div class="d-flex justify-content-between"><div class="mb-2" style={{ fontSize: "1rem",fontWeight: 700}}>Essential</div><div class=""><strong>Always Active</strong></div></div>
               <p> These cookies are essential for the website's fundamental
                        operation. They make it possible for essential features like network management,
                        accessibility, and security</p>

                        <div class="d-flex justify-content-between"><div class="mb-2" style={{ fontSize: "1rem",fontWeight: 700}}>Performance</div>
                        <div class=""><label class="switch">
  <input type="checkbox"/>
  <span class="toggler round"></span>
</label></div>
</div>
               <p>  These cookies gather data about user's activity on our website.
                        We can better identify which areas of our site are in need of upgrading and which are
                        the most popular. All of the data that these cookies gather is anonymous because it is
                        aggregated.</p>

                        <div class="d-flex justify-content-between"><div class="mb-2" style={{ fontSize: "1rem",fontWeight: 700}}>Functional</div><div class=""><label class="switch">
  <input type="checkbox"/>
  <span class="toggler round"></span>
</label></div></div>
               <p> These cookies are used to deliver more personalisation and
                        improved functionality. It enables improved, customised functionality on our website
                        to remember the selections you make.</p>

                        <div class="d-flex justify-content-between"><div class="mb-2" style={{ fontSize: "1rem",fontWeight: 700}}>Targeting</div><div class=""><label class="switch">
  <input type="checkbox"/>
  <span class="toggler round"></span>
</label></div></div>
               <p> We use these cookies to deliver ads that are more appropriate for
                        your interests. In addition, they track the success of advertising campaigns and set a
                        limit on how often you see ads. Information collected by these cookies may be shared
                        with third parties, such as advertisers.</p>
                </div>
                <div>
                <Button
                className="mb-2"
                variant="light"
                size="md"
                style={{background: "#fd6517",
                  border: "0",
                  padding: "6px 30px",
                  color: "#fff",
                  marginLeft:"12px",
                  transition: "0.4s",
                  borderRadius: "4px",
                float:"right" }}
                
              >
                Save Settings
              </Button>
                </div>
                </div>
              </>
            )}

            {/* <hr style={{ color: "black", width: "100%" }}></hr>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Button className='mb-2' variant="outline-light" size='md' style={{ width: "16vw" }} onClick={() => { this.setState({ Customise: true }) }}>Customise</Button>
              <Button
                className="mb-2"
                variant="light"
                size="md"
                style={{ width: "16vw" }}
                onClick={() => {
                  this.setState({ cookies: false });
                }}
              >
                Reject
              </Button>
              <Button
                className="mb-2"
                variant="light"
                size="md"
                style={{ width: "16vw" }}
                onClick={this.handleAccept}
              >
                Accept All
              </Button>
            </div> */}
          </Card>
          </div>
        ) : (
          <span></span>
        )}

        <Navbar />
        <section className="home-section">
        <div
          className="d-sm-flex align-items-center justify-content-between w-100 container new "
         
        >
          <div
            className="col-md-5 mb-4 mb-sm-0 headline"
            
          >
            <h1
              className="display-4 my-4 font-weight-bold "
              data-aos="fade-up"
              style={{ color: "#fd6517", fontWeight: "400" }}
            >
              Easily Build{" "}
              <span style={{ color: "#06475f", fontSize: "50px" }}>
                Powerful Forms
              </span>
            </h1>
            <div className="text">
              GigaForms is an online form builder that allows users to create and customize forms for various purposes such as data collection, surveys, registrations, payment forms, and more. It provides a drag-and-drop interface, making it easy to design forms without needing any coding skills.
            </div>
            <br />
            <Link
              to="/login"
              className="btn px-4 py-2 text-white mt-3 mt-sm-0"
              style={{ borderRadius: "10px", backgroundColor: "#06475f",fontWeight:"600" }}
            >
            Sign in
            </Link>
            <div
              to="#"
              className="btn px-4 py-2 mt-3 mt-sm-0 demo-btn"
             
              onClick={() => {
                this.setState({ show: true });
              }}
            >
             Request a demo
            </div>
          </div>
          <div className="col-md-6 lEDT" id="div-desktop">
            <img
              title="Giga Form"
              src={gigaformbanner}
              alt="img"
              data-aos="fade-left"
            />
          </div>
        </div>
        </section>
      

        <section className="about-section" id="about">
          <div className="container">
            <div className="row">
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column wow fadeInLeft">
                  <div className="author-desc">
                    <h2>GIGAFORMS</h2>
                  </div>
                  <figure className="image-1">
                    <Link
                      to="#"
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        src="https://www.influentialsoftware.com/wp-content/uploads/2020/11/etl-data-solutions-featured.png"
                        alt="img"
                        data-aos="fade-up-right"
                      />
                    </Link>
                  </figure>
                </div>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div className="inner-column" >
                  <div className="sec-title">

                    <h2>About GIGA-FORM</h2>


                    <h4>
                      Data Collection, Survey Reports and Dynamic Content
                      Creation Made Easy!
                    </h4>
                  </div>
                  <div className="text">
                    Giga-Form stands as a cutting-edge web and mobile app-based
                    platform, redefining the landscape of data collection,
                    surveys, and dynamic content creation applications. It
                    transcends traditional paper forms, offering a seamless and
                    user-friendly solution that revolutionizes the way data is
                    gathered and managed. By embracing GigaForms, users benefit
                    from real-time updates, instant collaboration, and swift
                    data processing, all leading to instantaneous report
                    generation. The platform ensures secure data storage,
                    providing proper allocation for future accessibility. With a
                    focus on qualitative data support, GigaForms grants users
                    control over their information, extracting valuable insights
                    for informed decision-making. Its user-friendly flexibility
                    allows for diverse applications, accommodating multiple
                    users while maintaining an up-to-date database. Notably,
                    GigaForms introduces robust form tracking capabilities,
                    enabling users to monitor each step of the process,
                    facilitating easy reporting and enhanced auditability.
                    Embracing cloud hosting, Giga-Form offers accessibility from
                    anywhere, empowering organizations to seamlessly manage data
                    remotely. Giga-Form isn't just a platform; it's a
                    transformative tool ushering in a new era of efficient,
                    user-friendly, and secure data management.
                  </div>

                  {/* <div className="btn-box">
                    <Link
                      to="/ContactUs"
                      className="theme-btn btn-style-one"
                      style={{ backgroundColor: "#fd6517" }}
                    >
                      Contact Us
                    </Link>
                  </div> */}
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <Link to="/ContactUs" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                      <div class="circle"></div>
                      <p class="btn-text">Contact Us</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section
          id="feature"
          className="services sections-bg"
          style={{ backgroundColor: "white" }}
          ref={ref => this.sectionRef = ref}
        >
          <div className="container p-6" data-aos="fade-up">
            <div className="sec-title text-center">
              <h2>Features</h2></div>
            <div className="row gy-4" data-aos-delay="100">

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src={features_dragdrop} alt='img' />
                  </div>
                  <h3 className="text" > Drag-and-Drop Form Builder</h3>
                  <p className="text">Easily create and customize forms using a drag-and-drop interface, with no coding required.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src={features_cusomization} alt='img' />
                  </div>
                  <h3 className="text" > Customizable Templates</h3>
                  <p className="text">Access a variety of pre-designed templates that can be tailored to meet specific business needs, saving time on form creation.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src={features_conditional} alt='img' />
                  </div>
                  <h3 className="text" >Conditional Logic</h3>
                  <p className="text">Set up rules within your forms to show or hide fields, send notifications, or trigger actions based on user responses, enhancing form interactivity and efficiency.</p>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Link to="/Feature" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                  <div class="circle"></div>
                  <p class="btn-text">View All</p>
                </Link>
              </div>


            </div>



          </div>
        </section>

      <Applicationarea/>
      <Benifits/>
      <ContactHome/>
       
<section className="bg-white">
  <div className="container">
    <div className="row">
      <div className="col-md-3">
        <div style={{margin:"10px 0",borderLeft:"2px solid #fd6517",display:"flex",justifyContent:"space-around",alignItems:"center" }}>
          <img src={happycustomers} alt="customer img" style={{height:"60px" ,width:"60px"}}/>
          <div>
        <strong style={{fontSize:"3rem",color:"#fd6517"}}>110</strong>
        <p style={{fontWeight:"600"}}>Number of Customer</p></div>
        </div>
      </div>
      <div className="col-md-3">
        <div style={{margin:"10px 0",borderLeft:"2px solid #fd6517",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
        <img src={countriesindia} alt="customer img" style={{height:"60px" ,width:"60px"}}/>
          <div>
        <strong style={{fontSize:"3rem",color:"#fd6517"}}>10 </strong>
        <p style={{fontWeight:"600"}}>Countries including India</p> </div>
        </div>
      </div>
      <div className="col-md-3">
        <div style={{margin:"10px 0",borderLeft:"2px solid #fd6517",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
        <img src={yearbusiness} alt="customer img" style={{height:"60px" ,width:"60px"}}/>
          <div>
        <strong style={{fontSize:"3rem",color:"#fd6517"}}>6</strong>
        <p style={{fontWeight:"600"}}>Years in Business</p> </div>
        </div>
      </div>
      <div className="col-md-3">
        <div style={{margin:"10px 0",borderLeft:"2px solid #fd6517",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
        <img src={satisfactionrate} alt="customer img" style={{height:"60px" ,width:"60px"}}/>
          <div>
        <strong style={{fontSize:"3rem",color:"#fd6517"}}>98%</strong>
        <p style={{fontWeight:"600"}}>Satisfaction Rate</p> </div>
        </div>
      </div>
    </div>
  </div>
</section>
        {/* <LogoSilider /> */}

        <Footer />
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          centered
          size="md"
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#fd6517",
              color: "white",
              height: "20px",
            }}
          >
            {/* <Modal.Title>Modal heading</Modal.Title>s */}
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="validationCustomUsername">
                  <Form.Label>Name</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      <img src="https://img.icons8.com/?size=24&id=3225&format=png" alt='img'></img>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      aria-describedby="inputGroupPrepend"
                      required
                      value={this.state.demoname}
                      onChange={(e) => {
                        this.setState({ demoname: e.target.value })
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a username.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="validationCustomUsername">
                  <Form.Label>Email</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      <img src="https://img.icons8.com/?size=24&id=53435&format=png" alt='img'></img>
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email id"
                      aria-describedby="inputGroupPrepend"
                      value={this.state.demoemail}
                      required
                      onChange={(e) => {
                        this.setState({ demoemail: e.target.value })
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a username.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Label>Contact Number</Form.Label>
                {/* <input type="number" className="form-control" name="Phone" placeholder="Enter Your Number" required value={this.state.number} onChange={event => { this.setState({ number: event.target.value }); }} /> */}
                <PhoneInput
                  containerStyle={{ width: "100%" }}
                  inputStyle={{ width: "100%", height: "40px" }}
                  country={"in"}
                  enableSearch={true}
                  value={this.state.demonumber}
                  onChange={(event) => {
                    this.setState({ demonumber: event });
                  }}
                />
              </div>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Message"
                className="mt-2"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  value={this.state.demomsg}
                  onChange={(e) => {
                    this.setState({ demomsg: e.target.value });
                  }}
                />
              </FloatingLabel>
            </div>
            <Button
              variant="primary"
              type="submit"
              className="mt-2"
              style={{
                float: "right",
                backgroundColor: "#fd6517",
                borderColor:"#fd6517",
                color: "white",
              }}
              onClick={(e) => {
                this.handledemoSubmit(e);
              }}
            >
              Submit
            </Button>
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeData: (token, requestBody) =>
      dispatch(fetchContact(token, requestBody)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
