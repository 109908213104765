import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import WorkflowSearchableDropDown from './WorkflowSearchableDropDown';
import { AddWorkflow, fetchWorkFlowTemplate, removeWorkFlowTemplate } from '../action';
import { fetchWorkflowList } from '../../workflows/action';
import { checkForUndoWorkflowTemplate, validateWorkflowList } from '../../../lib/utils';
import SearchableDropdown from '../../common/components/SearchableDropdown';

class AddWorkflowModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            workflowName: {},
        }
    }
    componentDidMount() {
        this.props.fetchWorkflowList(this.props.token.access);
        this.props.fetchWorkFlowTemplate(this.props.token.access);
    }
    handleAddWorkflow = () => {
        let requestBody = {
            "description": "",
            "workflow": this.state.workflowName.id,
            "template": this.props.templateId,
        }
        this.props.AddWorkflow(this.props.token.access, requestBody);
        this.props.handleClose();
    }
    handleRemoveWorkflow = () => {
        var workflowTemplateObj = checkForUndoWorkflowTemplate(this.props.workflowtemplatelist.results, this.state.workflowName.id)
        this.props.removeWorkFlowTemplate(this.props.token.access, workflowTemplateObj.id)

    }
    render() {
        var validatedWorkflowList = []
        if (this.props.workflowList.results.length > 0) {
            validatedWorkflowList = validateWorkflowList(this.props.workflowList.results, this.props.workflowtemplatelist.results)
        }
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Add Workflow</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <WorkflowSearchableDropDown
                        list={this.props.workflowList}
                        keyName='label'
                        onGetList={() => this.componentDidMount()}
                        defaultValue={this.state.workflowName.label !== undefined ? this.state.workflowName.label : ''}
                        onChange={(data) => this.setState({ workflowName: data })}
                        onSearchData={(data) => {
                            this.props.fetchWorkflowList(this.props.token.access, `search=${data}&`, 1);
                        }}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button variant={'outline-success'} onClick={() => { this.handleAddWorkflow(); window.location.reload() }} size='sm'>ADD</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        workflowList: state.workflowReducer.workflowList,
        workflowtemplatelist: state.formsReducer.workflowtemplatelist
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: (accessToken, search, pageNo) => dispatch(fetchWorkflowList(accessToken, search, pageNo)),
        fetchWorkFlowTemplate: (accessToken) => dispatch(fetchWorkFlowTemplate(accessToken)),
        AddWorkflow: (accessToken, requestBody) => dispatch(AddWorkflow(accessToken, requestBody)),
        removeWorkFlowTemplate: (accessToken, id) => dispatch(removeWorkFlowTemplate(accessToken, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkflowModal);
