import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { REQUEST_METHOD } from '../../../../lib/constants';
import { fetchApi } from '../../../../lib/utils';
import { updateLoader } from '../../../common/action'

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [], checkboxString: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ checkboxString: this.props.value })
        }
    }

    componentDidMount() {
        if (this.props.fieldData.master_data_code !== 'string') {
            this.props.setLoader(true);
            fetchApi(`/forms/template/${this.props.fieldData.master_data_code}/data/`,
                this.props.token.access,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    let data = []
                    successResponse.data.map((row) => {
                        return row.data.map((obj) => {
                            return data.push(obj.value)
                        })
                    })
                    this.props.setLoader(false);
                    this.setState({ options: data })
                })
        }
    }

    checkFormCheckValue = (value, string) => {
        const myArray = string.split(" | ");
        var returnVal = false
        if (string !== '') {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i] == value) {
                    returnVal = true
                    break
                }
            }
        }
        return returnVal
    }

    handleCheckBoxData = (data, checkBoxData) => {
        const myArray = this.state.checkboxString.split(" | ");
        if (myArray.includes(data)) {
            myArray.splice(myArray.indexOf(data), 1)
            this.setState({ checkboxString: myArray.join(' | ') })
            return myArray.join(' | ')
        }
        else {
            if (myArray[0] == '') {
                myArray.pop()
                myArray.push(data)
                this.setState({ checkboxString: myArray.join(' | ') })
                return myArray.join(' | ')
            }
            else {
                myArray.push(data)
                this.setState({ checkboxString: myArray.join(' | ') })
                return myArray.join(' | ')
            }
        }

    }

    render() {
        return (
            <div title={this.props.title}>
                {this.state.options.map((row, index) => {
                    return (<Form.Check key={index} type={`checkbox`} defaultChecked={this.checkFormCheckValue(row, this.props.value !== undefined ? this.props.value : '')} name="group1" label={row} onChange={(e) => this.props.onChange(this.handleCheckBoxData(row, e.target.checked))} />)
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (status) => dispatch(updateLoader(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
