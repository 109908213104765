import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_ORGANIZATIONS, FETCH_ORGANIZATIONS_DATA } from './actionTypes';

const initialState = {
    organizations: DEFAULT_DATA,
    organizationData: {}
}

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload
            };
        case FETCH_ORGANIZATIONS_DATA:
            return{
                ...state,
                organizationData: action.payload
            };
        default:
            return state;
    }
};

export default organizationReducer;
