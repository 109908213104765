import React, { useState } from 'react';
import "./application.css";
import { Link } from 'react-router-dom';
import features_dmsintegration from "../../../Images/features-dms-integration.jpg"

import CustomerFeedbackSurveys from "../../../Images/Customer Feedback Surveys.png"
import EventRegistrations from "../../../Images/Event Registrations.png"
import EcommerceOrderForms from "../../../Images/E-commerce Order Forms.png"
import HealthcarePatientIntake from "../../../Images/Healthcare Patient Intake.png"
import JobApplicationForms from "../../../Images/Job Application Forms.png"
import EducationalQuizzes from "../../../Images/Educational Quizzes and Exams.png"
import ContactLeadGeneration from "../../../Images/Contact and Lead Generation Forms.png"
import VolunteerSignupForms from "../../../Images/Volunteer Sign-up Forms.png"
export default function ApplicationArea() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
  };

  return (
   <>
     <section style={{ backgroundColor: "white" }}>
          <div className="container">
            <div className="sec-title text-center">
              <h2>Application Areas</h2>
            </div>
            <div className="container">
              <div className="" style={{ boxShadow: "0 2px 37px 0 #0000001a" }}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="application-tab-container">


                      <ul>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleBoxClick(0)}>Customer Feedback Surveys</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleBoxClick(1)}>Event Registrations</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleBoxClick(2)}>E-commerce Order Forms</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 3 ? 'active' : ''}`} onClick={() => handleBoxClick(3)}>Healthcare Patient Intake</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 4 ? 'active' : ''}`} onClick={() => handleBoxClick(4)}>Job Application Forms</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 5 ? 'active' : ''}`} onClick={() => handleBoxClick(5)}>Educational Quizzes and Exams</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 6 ? 'active' : ''}`} onClick={() => handleBoxClick(6)}>Contact and Lead Generation Forms</Link>
                        </li>
                        <li>
                          <Link className={`a-application__tabs-label ${activeIndex === 7 ? 'active' : ''}`} onClick={() => handleBoxClick(7)}>Volunteer Sign-up Forms</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className={`application-content-div ${activeIndex === 0 ? 'active' : ''}`}>
                      <img src={CustomerFeedbackSurveys} alt="img" style={{height:"280px",width:"100%"}} />
                      <p className='py-2'>
                        Giga-Forms is ideal for creating customer feedback forms that can be easily customized to gather insights about products, services, or customer experiences. The platform's templates and conditional logic features help in designing intuitive surveys that improve response rates.


                      </p>
                      <div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
                        <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                          <div class="circle"></div>
                          <p class="btn-text">Read More</p>
                        </Link>
                      </div>
                    </div>
                    <div className={`application-content-div ${activeIndex === 1 ? 'active' : ''}`}>
                    <img src={EventRegistrations} alt="img" style={{height:"280px",width:"100%"}} />
                      <p className='py-2'>
                        Organizations can use Giga-Forms to streamline event registration processes. The platform allows for custom registration forms, integrated payment gateways, and automated confirmation emails, making it simple to manage everything from small workshops to large conferences.
                      </p>
                      <div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
                        <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                          <div class="circle"></div>
                          <p class="btn-text">Read More</p>
                        </Link>
                      </div>
                    </div>
                    <div className={`application-content-div ${activeIndex === 2 ? 'active' : ''}`}>
                    <img src={EcommerceOrderForms} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
Giga-Forms supports building order forms that can be integrated with payment processors like PayPal, Stripe, and Square. Businesses can use it to accept payments, collect customer details, and manage orders directly through their forms.
</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
<div className={`application-content-div ${activeIndex === 3 ? 'active' : ''}`}>
<img src={HealthcarePatientIntake} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
Healthcare providers can create patient intake forms to collect medical histories, insurance details, and consent forms before appointments. Giga-Forms' secure data handling features ensure that sensitive patient data is protected.
</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
<div className={`application-content-div ${activeIndex === 4 ? 'active' : ''}`}>
<img src={JobApplicationForms} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
HR departments can streamline recruitment by using Giga-Forms to create job application forms. These forms can be customized to capture resumes, cover letters, and other necessary information, and can be linked to applicant tracking systems.
</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
<div className={`application-content-div ${activeIndex === 5 ? 'active' : ''}`}>
<img src={EducationalQuizzes} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
Educators can use Giga-Forms to create and administer quizzes, exams, and assessment forms. The platform allows for various question types, automated scoring, and instant feedback, making it a versatile tool for online education.

</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
<div className={`application-content-div ${activeIndex === 6 ? 'active' : ''}`}>
<img src={ContactLeadGeneration} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
Businesses can create contact forms and lead generation forms that integrate with CRM systems. Giga-Forms allows for easy customization and embedding on websites, helping companies capture and track potential customers.
</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
<div className={`application-content-div ${activeIndex === 7 ? 'active' : ''}`}>
<img src={VolunteerSignupForms} alt="img" style={{height:"280px",width:"100%"}} />
<p className='py-2'>
Nonprofits and community organizations can use Giga-Forms to manage volunteer sign-ups. The forms can include fields for availability, areas of interest, and emergency contact information, simplifying the coordination of volunteer efforts.

</p>
<div style={{ display: "flex", justifyContent: "start",position:"absolute", bottom:"0px" }}>
  <Link to="/" class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
    <div class="circle"></div>
    <p class="btn-text">Read More</p>
  </Link>
</div>
</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
   </>
  );
}
