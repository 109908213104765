import React, { useState } from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';


import SimpleForm from '../../../Images/SimpleForm.jpg';
import CardForm from '../../../Images/CardForm.jpg';

const FormTemplateTypes = [
    { name: "Simple Form Template", img: SimpleForm, value: "S" },
    { name: "Card Form Template", img: CardForm, value: "C" }
]

function CustomFormHeader(props) {

    const [createFormStatus, setCreateFormStatus] = useState(false)

    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingBlock: 7, justifyContent: "flex-end", paddingInline: 15, backgroundColor: "rgb(5 123 138)", position: "sticky", }}>

                <Button variant="outline-light" onClick={() => setCreateFormStatus(true)} size='sm'>Create New Form <i className="fa fa-plus" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></Button>

                <Modal show={createFormStatus} onHide={() => setCreateFormStatus(false)} centered size='lg'>
                    <Modal.Header style={{ backgroundColor: "rgb(5 123 138)", color: "#fff" }} closeButton>
                        <Modal.Title >Form Templates</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {
                            FormTemplateTypes.map((item, index) => {
                                return (
                                    <div key={index} className='form-template-style'
                                        onClick={() => {
                                            props.setCustom_template_type(item.value);
                                            props.setCreateFormStatus(true);
                                            setCreateFormStatus(false)
                                        }}>
                                        <img src={item.img} style={{ height: 50, width: 50, marginRight: 15 }} alt='img'/>
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </Modal.Body>
                </Modal>
                <OverlayTrigger
                    trigger="focus"
                    placement={'bottom'}

                    overlay={
                        <Popover id={`popover-positioned-bottom`} style={{ minWidth: 300, boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }}>
                            <Popover.Header style={{ backgroundColor: "rgb(5 123 138)", color: "#fff" }} as="h3">All Forms</Popover.Header>
                            <Popover.Body style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                {
                                    props.allFormList && props.allFormList.map((item, index) => {
                                        return (
                                            <div key={index} className='form-template-style' style={{ width: "100%" }} onClick={() => {
                                                props.updateForm(item)
                                            }}>
                                                <img src={item.custom_template_type == "S" ? SimpleForm : CardForm} style={{ height: 50, width: 50, marginRight: 15 }} alt='img'/>
                                                {item.label}
                                            </div>
                                        )
                                    })
                                }

                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button size='sm' className='ms-3 me-3' variant="outline-light">Select Form Templates <i className="fa fa-chevron-down" aria-hidden="true" style={{ marginLeft: 7 }}></i></Button>
                </OverlayTrigger>
                <Button size='sm' variant='outline-light' onClick={() => { props.previewForm(); }} >
                    Preview <i className="fa fa-arrow-right" aria-hidden="true" style={{ marginLeft: 7 }}></i>
                </Button>
            </div>
        </div>
    )
}

export default CustomFormHeader