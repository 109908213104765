import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_ALL_TEAMS, FETCH_SELECTED_USERS } from './actionType';
const initialState = {
    teams: DEFAULT_DATA,
    selectedUsers:DEFAULT_DATA,
   
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_TEAMS:
            return {
                ...state,
                teams: action.payload
            };
            case FETCH_SELECTED_USERS:
                return {
                    ...state,
                    selectedUsers: action.payload
                };
        default:
            return state;
    }
};

export default notificationReducer;
