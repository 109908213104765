import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { BASE_URL } from "../../lib/constants";
import * as ORGANIZATIONS_ACTION_TYPES from './actionTypes';
import { fetchApi } from "../../lib/utils";
import { FETCH_ORGANIZATIONS_DATA } from "./actionTypes";
import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants";


export function createOrganization(accessToken, requestBody) {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    let formdata = new FormData();
    formdata.append("logo", requestBody.logo);
    formdata.append("name", requestBody.name);
    formdata.append("website", requestBody.website);
    formdata.append("email", requestBody.email);
    formdata.append("phone", requestBody.phone);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetch(`${BASE_URL}/organization/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(fetchOrganizations(accessToken));
                dispatch(setNotification(true, 'Organization Created Successfully', 'success'));
            }
            )
            .catch(error => {
                console.log('error', error);
                dispatch({ type: LOADING_END });
            });
    }
}

export function fetchOrganizations(accessToken, pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/organization/?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: ORGANIZATIONS_ACTION_TYPES.FETCH_ORGANIZATIONS, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function updateOrganizationData(accessToken, Id, requestBody) {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    let formdata = new FormData();
    formdata.append("logo", requestBody.logo);
    formdata.append("name", requestBody.name);
    formdata.append("website", requestBody.website);
    formdata.append("email", requestBody.email);
    formdata.append("phone", requestBody.phone);

    let requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetch(`${BASE_URL}/organization/${Id}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(setNotification(true, 'Organization Updated Successfully', 'success'));
                dispatch(fetchOrganizations(accessToken));
                dispatch({ type: LOADING_END })
            }
            )
            .catch(error => {
                console.log('error', error);
                dispatch({ type: LOADING_END });
            });
    }
}

export function fetchOrganizationData(accessToken, Id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/organization/${Id}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_ORGANIZATIONS_DATA, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function deleteOrganization(accessToken, organizationId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/organization/${organizationId}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setNotification(true, 'Organization Deleted Successfully', 'danger'))
                dispatch(fetchOrganizations(accessToken))
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }, null, RESPONSE_TYPE.NULL
        )
    }
}