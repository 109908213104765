import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_PROJECTS, FETCH_PROJECT_DETAILS, FETCH_PROJECT_FORMS, PROJECT_MEMBER_LIST } from './actionTypes';

const initialState = {
    projects: {},
    projectDetails: {},
    formList: DEFAULT_DATA,
    projectMembers: [],

};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECTS:
            return {
                ...state,
                projects: action.payload
            };
        case FETCH_PROJECT_DETAILS:
            return {
                ...state,
                projectDetails: action.payload
            }
        case FETCH_PROJECT_FORMS:
            return {
                ...state,
                formList: action.payload
            }
        case PROJECT_MEMBER_LIST:
            return {
                ...state,
                projectMembers: action.payload
            }
        default:
            return state;
    }
};

export default projectReducer;
