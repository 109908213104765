import { FETCH_FILE_LIST} from './actionTypes';
import { DEFAULT_DATA } from '../../lib/constants';

const initialState = {
    filelist: DEFAULT_DATA,
    
};

const fileExplorerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILE_LIST:
            return {
                ...state,
                filelist: action.payload
            };
        default:
            return state;
    }
};

export default fileExplorerReducer;
