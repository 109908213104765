import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import Background from "../../../Images/green.webp";
import { fetchAccessToken, fetchconfurigation } from '../action';
import Toaster from '../../common/components/Toaster';
import { checkLogin } from '../../../lib/utils';
import ForgotPasswordModal from './ForgotPasswordModal';
import GIGA from "../../../Images/final.png"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            showPassword: true,
            showForgotPasswordModal: false,
            configuration: {},
        }
    }

    handleLoginAction(event) {
        event.preventDefault()
        let requestBody = {
            "email": this.state.email,
            "password": this.state.password
        }
        this.props.userLogin(requestBody, this.props.navigate);
    }
    componentDidMount() {
        this.props.configuration((data) => {
            this.setState({ configuration: data.results[0] ? data.results[0] : {} })
        });
        checkLogin("login", this.props.navigate);

    }

    render() {
        return (
            <div className='full-height' style={{ backgroundImage: `url(${Background})` }}>
                <Row style={{ position: "absolute", right: 0 }} >
                    <Col>
                        <Toaster />
                    </Col>
                </Row>
                <div className="main-content-container container-fluid full-margin-block" >
                    <h3 className='text-center fw-bold text-uppercase'>WelCome To Giga-Forms</h3>
                    <Row className='d-flex justify-content-center'>
                        <Col md='4'>
                            <Card className='shadow p-3 mb-5 bg-white rounded' >
                                <Card.Body className='m-4'>
                                    {this.state.configuration.logo_url !== undefined ?
                                        <div style={{ display: "flex", justifyContent: "center", }}>
                                            <div style={{ height: 100, width: 100 }}>
                                                <img src={this.state.configuration.logo_url} alt='img'/>
                                            </div>
                                        </div> :
                                        <h3 className='text-center fw-normal'><img src={GIGA} style={{ width: "200px" }} alt='img'/></h3>
                                    }


                                    <Form onSubmit={(e) => { this.handleLoginAction(e) }}>
                                        <Form.Group className="mb-3 mt-3 font-medium" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" required
                                                onChange={event => { this.setState({ email: event.target.value }); }} />
                                        </Form.Group>
                                        <Form.Group className="mb-3 font-medium" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type={this.state.showPassword ? "password" : "text"} placeholder="Password" required
                                                onChange={event => { this.setState({ password: event.target.value }); }} />
                                            {
                                                this.state.showPassword ?
                                                    <i className="fa fa-eye fa-lg " id="togglePassword" style={{ cursor: "pointer", position: "relative", top: "-25px", float: "right", marginRight: "15px", color: "gray" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}></i> :
                                                    <i className="fa fa-eye-slash fa-lg" id="togglePassword" style={{ cursor: "pointer", position: "relative", top: "-25px", float: "right", marginRight: "15px", color: "gray" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}></i>
                                            }
                                        </Form.Group>
                                        <Button variant="success" type="submit" className='full-width mt-3 mb-4'>
                                            LOGIN
                                        </Button>
                                        <div style={{ margin: "auto", width: 'fit-content' }}>
                                            <a style={{ cursor: 'pointer' }} className='full-width mt-3 mb-4' onClick={() => this.setState({ showForgotPasswordModal: !this.state.showForgotPasswordModal })}>Forgot Password?</a>
                                        </div>
                                    </Form>
                                    {this.state.configuration.sign_up == 0 ? <></> :
                                        <p className='footer text-center'>Don't have an account?<a className="text-decoration-none text-success font-weight-bolder" href='/register'> SignUp</a></p>
                                    }

                                    <ForgotPasswordModal showModal={this.state.showForgotPasswordModal} handleClose={() => this.setState({ showForgotPasswordModal: false })} />
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: (requestBody, navigate) => dispatch(fetchAccessToken(requestBody, navigate)),
        configuration: (getData) => dispatch(fetchconfurigation(getData)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
