import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_ALL_TEAMS, FETCH_TEAM, FETCH_TEAM_MEMBERS } from './actionTypes';

const initialState = {
    teams: DEFAULT_DATA,
    teamData: {},
    teamMembers: DEFAULT_DATA
};

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_TEAMS:
            return {
                ...state,
                teams: action.payload
            };
        case FETCH_TEAM:
            return {
                ...state,
                teamData: action.payload
            };
        case FETCH_TEAM_MEMBERS:
            return {
                ...state,
                teamMembers: action.payload
            };
        default:
            return state;
    }
};

export default teamReducer;
