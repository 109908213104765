import React, { Component } from 'react'

import Footer from "./lfooter";
import Navbar from "./Navbr";


export default class faq extends Component {
  render() {
    return (
      <div>
       
<Navbar/>
<div>
<div className="card" style={{paddingTop:'20px', marginTop:'30px', height:'500px'}}>
  
  <div className="container">
    <h4><b>GO GREEN</b></h4> 
    <p>Earth is like our home and we must make efforts to keep it clean and green. On the occasion of World Environment Day, let us promise to make it a better place to live! We cannot imagine life without the environment. We cannot imagine prosperity without the environment. We must take charge of making it our priority.</p> 
  </div>
</div>
</div>
    <Footer />
      </div>


    )
  }
}

