import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { validatePosition } from '../../../lib/utils';
import MyGoogleMap from '../../common/components/MyGoogleMap';
import { fetchLocation } from '../action';

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

class UserLocation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fromDate: "",
            toDate: ""
        }
    }

    handleCreateAction = (e) => {
        e.preventDefault()
        this.props.getLocation(this.props.params.id, this.state.fromDate, this.state.toDate)
    }


    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Card>
                    <Card.Body>
                        <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                            <Row>
                                <Col>
                                    <Form.Label>From Date <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" type="date" placeholder="From Date" required value={this.state.fromDate}
                                        onChange={event => { this.setState({ fromDate: event.target.value }); }} />
                                </Col>
                                <Col>
                                    <Form.Label>To Date</Form.Label>
                                    <Form.Control size="sm" type="date" placeholder="To Date" value={this.state.toDate}
                                        onChange={event => { this.setState({ toDate: event.target.value }); }} />
                                </Col>
                                <Col className='d-flex align-items-end' >
                                    <Button type='submit' variant="outline-success" style={{ width: "35%" }} size='sm'>Track</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Row className="m-2 mt-4">
                            <MyGoogleMap />
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userLocation: state.usersReducer.userLocation
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLocation: (id, startDate, endDate) => dispatch(fetchLocation(id, startDate, endDate)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLocation);
