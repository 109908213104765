import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateWorkflow } from '../action';
import { setNotification } from '../../common/action';

class WorkflowUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            description: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({ label: this.props.selectedData.label, description: this.props.selectedData.description })
        }
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description
        }
        this.props.handleClose();
        if (requestBody["label"].replace(/\s/g, '').length) {
            this.props.editWorkflow(this.props.token.access, this.props.selectedData.id, requestBody);
        }
        else {
            this.props.setNotification(true, "Please Provide Proper Name And Description", "danger")
        }
      
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update Workflow</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Form.Group className="mb-3">
                            <Form.Label>Workflow Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Workflow Name" required
                                onChange={event => { this.setState({ label: event.target.value }); }}
                                value={this.state.label} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Description"
                                onChange={event => { this.setState({ description: event.target.value }); }}
                                value={this.state.description} />
                        </Form.Group>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editWorkflow: (accessToken, pk, requestBody) => dispatch(updateWorkflow(accessToken, pk, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowUpdateModal);
