import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, } from 'react-bootstrap';
import { fetchLogData } from '../action';
import { formateDate } from '../../../lib/utils';
import DataTable from '../../common/components/DataTable';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';


class Logs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getLogs(this.props.token.access)
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <h5>Logs</h5>
                        <Card>
                            <Card.Body className='font-medium'>
                                <div className='mt-3 font-medium'  >
                                    {
                                        this.props.logData && this.props.logData.results && this.props.logData.results.length > 0 &&
                                        <DataTable
                                            totalItemsCount={this.props.logData.count}
                                            headers={[`Name`, `Model`, `Action`, `Time`, `Action Type`, `status`]}
                                            onPageChange={(pageNumber) => this.props.getLogs(this.props.token.access, pageNumber)}
                                            body={this.props.logData.results.map((row, index) => {
                                                return [
                                                    row.actor[0].name,
                                                    row.model,
                                                    row.action_type,
                                                    formateDate(row.action_time),
                                                    row.remarks,
                                                    row.status
                                                ]
                                            })
                                            } />
                                    }

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        logData: state.logreducer.logData,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLogs: (accessToken, pageNumber) => dispatch(fetchLogData(accessToken, pageNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
