import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Footer from "./lfooter";
import Navbar from "./Navbr";

export default class faq extends Component {
  render() {
    return (
      <div>
       
<Navbar/>
    <div className="container" style={{paddingTop:"30px",paddingBottom:"30px", }}>
 
   
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Giga Forms ?</Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Giga Forms is a web and mobile app-based platform that provides easy to use solution for data collection, survey and dynamic content creation applications. Replacing paper forms with Giga-forms brings in many benefits such as real-time update, real-time collaboration, instant data processing and instant report building, proper allocation & storage of data for future accessibility. It has the potential to support the qualitative data & gives you an access to data control as well. It has the flexibility to be used by multiple users for various purposes yet maintaining a proper and up-to-date database. Also, it allows you to track your forms through each step of the process, resulting in an easy reporting and auditability. Cloud hosting allows the user to access it remotely.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" style={{marginTop:"10px"}}>
        <Accordion.Header>Why Giga Forms?</Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Giga Forms  will have your forms up and running in seconds! We’re the easiest form builder with the most advanced capabilities, including the industry’s top design and customization tools.It is a powerful online application that allows anyone to quickly create custom online forms. Its intuitive drag-and-drop user interface makes form building incredibly simple, and doesn’t require you to write a single line of code. Using Giga Forms , you can create and publish forms, integrate them into your site, and receive responses by email.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" style={{marginTop:"10px"}}>
        <Accordion.Header>Features of Giga Forms  </Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Giga Forms is a comprehensive platform that empowers businesses and individuals to effortlessly create, customize, and deploy highly responsive and intuitive forms. Whether you need simple contact forms, surveys, registration forms, or complex application forms, Giga Forms offers a user-friendly interface and powerful features to meet all your needs. Bid farewell to tedious paper-based processes and modernize your data management practices with our feature rich platform. Experience the freedom of accessible and controllable data like never before!
        <div >

                            <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Form Builder:-</b> Effortlessly design unique and customizable forms using our field properties.To enhance the process further, we have also implemented a user-friendly drag-and-drop interface that allows you to build forms by simply selecting and arranging various form elements such as text fields, checkboxes, radio buttons, and dropdown menus.</li>
                                <li style={{textAlign:'justify'}}><b>Form Sharing:-</b> With Giga Forms, you get the flexibility to integrate forms into your web pages, share form links across your websites and social media platforms, deliver forms through email, or securely share them among your team members. Our platform guarantees uninterrupted form accessibility and submission, irrespective of the web browser or device your audience uses. Expand your reach by sharing forms across diverse platforms and enjoy streamlined data collection.</li>
                                <li style={{textAlign:'justify'}}><b>Save now and Resume later:-</b> No need to worry about accidental loss of data due to interruptions or technical issues,with Gigaforms allow your users to save partially filled forms, assuring no data is lost and enabling convenient completion at their own pace. This feature not only makes form filling effortless but also gives a worry-free experience.</li>
                                <li style={{textAlign:'justify'}}><b>Integrate and share:-</b> Streamline and simplify your workflows with our integration capabilities. Seamlessly connect your online forms with various apps to initiate actions based on the responses you receive. By integrating Giga Forms with an online app, you can automatically transfer form data to that app and automate all your workflows easily. Explore our integration feature to discover how these integrations can significantly enhance your productivity.</li>
                                <li style={{textAlign:'justify'}}><b>Email Alerts:-</b> Receive prompt email alerts whenever a form is submitted, ensuring you're always up to date. Keep the communication flow smooth by automatically sending confirmation emails for every action performed on GigaForms . With these modern and robust functionalities, you can maintain a seamless correspondence with your clients and team members.</li>
                                <li style={{textAlign:'justify'}}><b>Responsive Forms:-</b> When it comes to form filling, it's essential to provide users with an intuitive and convenient interface, regardless of the device or browser.To conquer every screen size with responsive forms that automatically adjust their layout based on the screen size and resolution of the device being used. This ensures that the forms are readable, accessible, and easy to interact with in all kind of devices.</li>
                                <li style={{textAlign:'justify'}}><b>Export Reports:-</b> GigaForms empowers users to effortlessly create customized forms to their specific needs, generate and export reports in multiple formats. Experiene report exporting in Excel, CSV, and PDF formats with just a few clicks. With this feature you can utilize your form data in the most effective and efficient way possible.</li>
                            </ol>
                            

                        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" style={{marginTop:"10px"}}>
        <Accordion.Header>Do I need coding experience to create a form?</Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Not at all! With Giga Forms ’s drag-and-drop builder, you don’t need any coding experience to create the perfect form for your needs. Advanced users can go directly into the HTML code and make adjustments, but it’s absolutely not necessary. With our Theme Designer, you can change the style of your form with just a few clicks.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" style={{marginTop:"10px"}}>
        <Accordion.Header><a href='/Pricing' style={{textDecoration:"none",color:"black"}}>Is Giga Forms  free?</a></Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Giga Forms  has multiple subscription options, including a free plan. Free users have access to all of the same features as paid users, but with lower limits on form counts, Team Count, Workflow Count, Custom Forms , Email Count, Upload space and submission storage. To check out the different options, visit our Pricing page
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" style={{marginTop:"10px"}}>
        <Accordion.Header>How secure is my data with Giga Forms  ?</Accordion.Header>
        <Accordion.Body style={{textAlign:"justify"}}>
        Giga Forms takes the security of your data very seriously. We encrypt all data. Giga Forms also adheres to strict data protection laws by allowing users to store their data on secure servers.
        </Accordion.Body>
      </Accordion.Item>
     
    </Accordion>
    
</div>

    <Footer />
      </div>


    )
  }
}
