import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchFormsList, fetchFields, fetchFormData } from '../../forms/action';
import { fetchUserReports, fetchTemplateUser } from '../action';
import {getAutoSumField, arrangeAutoSum, exportToCSV } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';

class UserReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            selectedData: {},
            templateId: '',
            startDate: '',
            endDate: '',
            user: '',
            templateName: '',
            autoSumFields: [],
            arrangedAutoSum: {},
            excelExportData: []
        }
    }

    componentDidMount() {
        this.props.getFormList(this.props.token.access);
        this.props.getUserReportData(this.props.token.access);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.fields.length > 0) {
            this.state.autoSumFields = getAutoSumField(this.props.fields)
        }
        if (this.props.fields && this.props.fields.length > 0 && this.props.formData && this.props.formData.data && this.props.formData.data.length > 0) {
            this.state.arrangedAutoSum = arrangeAutoSum(this.state.autoSumFields, this.props.formData.data)
        }
        if (prevState.templateId !== this.state.templateId) {
            this.props.fetchTemplateUser(this.props.token.access, this.state.templateId)
        }
    }

    handleSubmitAction = (event) => {
        event.preventDefault()
        if (this.state.templateId) {
            let requestBody = `template=${this.state.templateId}`
            this.props.getUserReportData(this.props.token.access, requestBody)
        }
        else if (this.state.startDate && this.state.endDate) {
            let requestBody = `startdate=${this.state.startDate}&enddate=${this.state.endDate}`
            this.props.getUserReportData(this.props.token.access, requestBody)
        }
        else if (this.state.startDate && this.state.endDate && this.state.templateId) {
            let requestBody = `startdate=${this.state.startDate}&enddate=${this.state.endDate}&template=${this.state.templateId}`
            this.props.getUserReportData(this.props.token.access, requestBody)
        }
        if (this.state.user) {
            let requestBody = `user=${this.state.user}`
            this.props.getUserReportData(this.props.token.access, requestBody)
        }
        if (this.state.startDate && this.state.endDate && this.state.user) {
            let requestBody = `startdate=${this.state.startDate}&enddate=${this.state.endDate}&user=${this.state.user}`
            this.props.getUserReportData(this.props.token.access, requestBody)
        }
    }

    render() {
        console.log("form list",this.props.formList)
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <h6>User Reports</h6>
                            </Col>
                            <Col md="2">
                                <Button variant='success' style={{ width: "100%" }} onClick={() => {
                                    let excelExportData = this.props.userreport && this.props.userreport.data
                                    if (excelExportData.length === 0) {
                                        this.props.setNotification(true, "No data available", "success")
                                    }
                                    else {
                                        exportToCSV(excelExportData, 'AIHDUserData')
                                    }
                                }}>Export</Button>
                            </Col>
                        </Row>
                        <Card className='mt-2'>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleSubmitAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Select template</Form.Label>
                                            <Form.Select size='sm' value={this.state.templateId} onChange={(event) => { this.setState({ templateId: event.target.value }) }} >
                                                <option value={''}>-Select  Template-</option>
                                                {this.props.formList && this.props.formList.data && this.props.formList.data.map((row, index) => {
                                                    return (
                                                        <option value={row.id} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control size="sm" type="date" placeholder="Enter Start Date" value={this.state.startDate}
                                                onChange={event => { this.setState({ startDate: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control size="sm" type="date" placeholder="Enter End Date" value={this.state.endDate}
                                                onChange={event => { this.setState({ endDate: event.target.value }); }} />
                                        </Col>
                                        {
                                            this.props.templateUsers && this.props.templateUsers.results && this.props.templateUsers.results.length > 0 &&
                                            <Col>
                                                <Form.Label>Select User</Form.Label>
                                                <Form.Select size='sm' value={this.state.templateUsers} onChange={(event) => { this.setState({ user: event.target.value }) }}>
                                                    <option value={''}>-Select  User-</option>
                                                    {this.props.templateUsers && this.props.templateUsers.results && this.props.templateUsers.results.map((row, index) => {
                                                        return (
                                                            <option value={row.user.id} key={index}>{row.user.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Col>
                                        }
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>SEARCH</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className='mt-3 font-medium'>
                                    {
                                        this.props.userreport && this.props.userreport.data && this.props.userreport.data.length > 0 &&
                                        <DataTable
                                            totalItemsCount={this.props.userreport.data.length}
                                            onPageChange={(pageNumber) => this.props.getFormData(this.props.token.access, this.props.params.id, pageNumber)}
                                            headers={Object.keys(this.props.userreport.data[0])}
                                            body={
                                                this.props.userreport.data.map((obj, index) => {
                                                    return Object.values(obj)
                                                })
                                            }
                                        />
                                    }
                                </div>
                                <DeleteModal modalText={"Delete Form ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        templateUsers: state.reportsReducer.templateUsers,
        fields: state.formsReducer.fields,
        formData: state.formsReducer.formData,
        formList: state.formsReducer.formList,
        userreport: state.reportsReducer.userreport,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTemplateUser: (accessToken, templateId) => dispatch(fetchTemplateUser(accessToken, templateId)),
        getFormData: (accessToken, templateId) => dispatch(fetchFormData(accessToken, templateId)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
        getUserReportData: (accessToken, requestBody) => dispatch(fetchUserReports(accessToken, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserReport);
