import React, { Component } from 'react';
import { Table, Pagination, Col, Row } from 'react-bootstrap';

const ITEM_PER_PAGE_LIST = 10;

export default class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
        }
    }

    getPageCount() {
        return Math.ceil(parseInt(this.props.totalItemsCount) / parseInt(ITEM_PER_PAGE_LIST));
    }

    handlePageChange(pageNumber) {
        this.setState({ currentPage: pageNumber });
        this.props.onPageChange(pageNumber)
    }


    renderPaginationButton() {
        let page = [];
        if (this.getPageCount() < 10) {
            for (let i = 1; i <= this.getPageCount(); i++) {
                page.push(
                    <Pagination.Item key={i} active={i === this.state.currentPage}
                        onClick={() => this.handlePageChange(i)}>{i}</Pagination.Item>
                )
            }
        }
        else {
            page.push(
                <Pagination.Item key={1} active={1 === this.state.currentPage}
                    onClick={() => this.handlePageChange(1)}>{1}</Pagination.Item>
            )
            {
                this.state.currentPage === 2 ?
                    page.push(
                        <Pagination.Item key={2} active={2 === this.state.currentPage}
                            onClick={() => this.handlePageChange(2)}>{2}</Pagination.Item>) :
                    page.push(
                        <Pagination.Ellipsis />
                    )
            }
            {
                this.state.currentPage > 2 && this.state.currentPage < this.getPageCount() - 1 ?
                    page.push(
                        <Pagination.Item key={Math.ceil(this.state.currentPage)} active={this.state.currentPage}
                            onClick={() => this.handlePageChange(this.state.currentPage)}>{this.state.currentPage}</Pagination.Item>
                    ) : <></>

            }

            {
                this.state.currentPage == this.getPageCount() - 1 ?
                    page.push(
                        <Pagination.Item key={this.getPageCount() - 1} active={this.getPageCount() - 1 === this.state.currentPage}
                            onClick={() => this.handlePageChange(this.getPageCount() - 1)}>{this.getPageCount() - 1}</Pagination.Item>) :
                    page.push(
                        <Pagination.Ellipsis />
                    )
            }
            page.push(
                <Pagination.Item key={this.getPageCount()} active={this.getPageCount() === this.state.currentPage}
                    onClick={() => this.handlePageChange(this.getPageCount())}>{this.getPageCount()}</Pagination.Item>
            )
        }
        return (
            <Pagination size="sm">
                <Pagination.First onClick={() => this.handlePageChange(1)} />
                <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage > 1 ? this.state.currentPage - 1 : 1)} />
                {page}
                <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage < this.getPageCount() ? this.state.currentPage + 1 : this.getPageCount())} />
                <Pagination.Last onClick={() => this.handlePageChange(this.getPageCount())} />
            </Pagination>
        )
    }

    renderTableDataCountStats() {
        let startCounter = parseInt(this.state.currentPage - 1) * parseInt(ITEM_PER_PAGE_LIST) + 1;
        let endCounter = parseInt(this.state.currentPage) * parseInt(ITEM_PER_PAGE_LIST);
        if (endCounter > parseInt(this.props.totalItemsCount)) {
            endCounter = this.props.totalItemsCount;
        }
        return `Showing ${startCounter} to ${endCounter} of ${this.props.totalItemsCount} entries`
    }



    render() {
        console.log("body",this.props.body)
        return (
            <div>
                <Table striped bordered hover style={{overflowX:"auto"}}>
                    <thead>
                        <tr>
                            {
                                this.props.headers.map((row, index) => {
                                    return (<th key={index}> {row} </th>)
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.body.length === 0 ?
                            <tr className="no-data" >
                                <td style={{ width: "100vw" }} colSpan={this.props.headers.length}>
                                    <div className="text-center">No Data Available</div>
                                </td>
                            </tr>
                            : this.props.body.length > 0 && this.props.body.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {row.map((td, index) => {
                                            return (<td key={index}>{td}</td>)
                                        })}
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
                <div className="pagination-btn">
                    <Row>
                        <Col md="8">
                            <div className={'mt-2'} >
                                <b>{this.renderTableDataCountStats()}</b>
                            </div>
                        </Col>
                        <Col md="4" className='d-flex justify-content-end' >
                            {this.getPageCount() > 1 ?
                                this.renderPaginationButton() :
                                null}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
