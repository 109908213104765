import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Col, Row, } from 'react-bootstrap';
import { updateUserProfile } from '../action';
import { setNotification } from '../../common/action';


class UserUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            is_superuser: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({
                name: this.props.selectedData.name,
                email: this.props.selectedData.email,
                phone: this.props.selectedData.phone,
                is_superuser: this.props.selectedData.is_superuser,
            })
        }
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.name,
            "phone": this.state.phone,
            "email": this.state.email,
            "password": this.state.phone,
            "is_superuser": this.state.is_superuser,
        }
        this.props.handleClose();
        if (requestBody["name"].replace(/\s/g, '').length) {
            this.props.editUser(this.props.token.access, requestBody, this.props.selectedData.id);
        }
        else {
            alert('enter valid credentials')
        }
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>User Name<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter User Name" required
                                        onChange={event => { this.setState({ name: event.target.value }); }}
                                        value={this.state.name} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>E - mail<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="email" placeholder="Enter e-mail" required
                                        onChange={event => { this.setState({ email: event.target.value }); }}
                                        value={this.state.email} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="number" placeholder="Enter Phone" required
                                        onChange={event => { this.setState({ phone: event.target.value }); }}
                                        value={this.state.phone} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label>Super User</Form.Label>
                                <Form.Check type="switch" label="Is the user a Super User ?" checked={this.state.is_superuser}
                                    onChange={() => this.setState({ is_superuser: !this.state.is_superuser })} />
                            </Col>
                        </Row>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editUser : (accessToken, requestBody, userId) => dispatch(updateUserProfile(accessToken, requestBody, userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateModal);
