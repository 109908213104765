import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { updateOrganizationData, fetchOrganizations } from '../action'

class OrganizationsUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            logo: "",
            website: "",
            email: "",
            phone: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState(
                {
                    name: this.props.selectedData.name,
                    logo: this.props.selectedData.logo,
                    website: this.props.selectedData.website,
                    email: this.props.selectedData.email,
                    phone: this.props.selectedData.phone
                }
            )
        }
    }

    handleUpdateAction(event) {
        event.preventDefault();
        let requestBody = {
            "name": this.state.name,
            "logo": this.state.logo,
            "website": this.state.website,
            "email": this.state.email,
            "phone": this.state.phone
        }
        this.props.handleClose();
        this.props.editOrganizarion(this.props.token.access, this.props.selectedData.id, requestBody);
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form
                        onSubmit={(e) => {
                            this.handleUpdateAction(e);
                        }}
                        className='font-medium'>
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Organization Name<span className='text-danger'>*</span></Form.Label>
                                <Form.Control size='sm' type="text" placeholder="Enter Organization Name" required
                                    onChange={event => { this.setState({ name: event.target.value }); }}
                                    value={this.state.name} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Logo<span className='text-danger'>*</span></Form.Label>
                                <Form.Control size="sm" type="file" placeholder="upload The Logo" required
                                    onChange={event => { this.setState({ logo: event.target.files[0] }) }} />
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Website<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter Website"
                                        onChange={event => { this.setState({ website: event.target.value }); }}
                                        value={this.state.website} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="email" placeholder="Enter Email"
                                        onChange={event => { this.setState({ email: event.target.value }); }}
                                        value={this.state.email} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter Phone"
                                        onChange={event => { this.setState({ phone: event.target.value }); }}
                                        value={this.state.phone} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm'>UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchOrganizations: (accessToken) => dispatch(fetchOrganizations(accessToken)),
        editOrganizarion: (accessToken, Id, requestBody) => dispatch(updateOrganizationData(accessToken, Id, requestBody))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsUpdateModal)