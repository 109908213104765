import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { setBodyDataType } from '../FieldTypes'

export default class Table extends Component {
    constructor(props) {
        super(props)

        this.state = {
            table_data: { headers: [{ type: "text", name: "header1", api: "" }, { type: "text", name: "header2", api: "" }, { type: "text", name: "header3", api: "" }], body: [] }
        }
    }

    componentDidMount = () => {
        if (this.props.data.table_data !== '') {
            if (typeof (this.props.data.table_data) == 'string') {
                this.setState({ table_data: JSON.parse(this.props.data.table_data) })
            }
        }
        if (this.props.defaultValue !== '') {
            if (typeof (this.props.defaultValue) == 'string') {
                this.setState({ table_data: JSON.parse(this.props.defaultValue) })
            }
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.data !== this.props.data) {
            if (this.props.data.table_data !== '') {
                if (this.props.data.table_data !== '' && typeof (this.props.data.table_data) == 'string') {
                    this.setState({ table_data: JSON.parse(this.props.data.table_data) })
                }
            }
        }

        if (prevProps.defaultValue !== this.props.defaultValue) {
            if (this.props.data.defaultValue !== '') {
                if (this.props.data.defaultValue !== '' && typeof (this.props.data.defaultValue) == 'string') {
                    this.setState({ table_data: JSON.parse(this.props.data.defaultValue) })
                }
            }
        }
    }

    setRadioFieldData = (index, list, data) => {
        var myList = [...list]
        list.map((item, i) => {
            if (item == 'yes') {
                myList[i] = ' '
            }
            else if (i == index) {
                myList[i] = data
            }
        })
        return myList
    }


    render() {
        return (
            <div style={{ border: "0.5px solid gray", }}>
                {
                    this.state.table_data.headers &&
                    <div>
                        <div style={{ borderBottom: "0.5px solid gray" }} >
                            <Row>
                                {
                                    this.state.table_data.headers.length > 0 && this.state.table_data.headers.map((item, index) => {
                                        return (
                                            <Col key={index} style={{ borderRight: index !== this.state.table_data.headers.length - 1 && "1px solid black", fontWeight: "bold", paddingBlock: 5, textAlign: "center" }}>
                                                {item.name}
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>

                        <div>
                            {
                                this.state.table_data.body.length > 0 && this.state.table_data.body.map((item, index) => {
                                    return (
                                        <div key={index} style={{ borderBottom: index !== this.state.table_data.body.length - 1 && "0.5px solid gray" }} >
                                            <Row>
                                                {
                                                    item.length > 0 && item.map((value, i) => {
                                                        return (
                                                            <Col key={i}
                                                                style={{ borderRight: i !== item.length - 1 && "0.5px solid black", paddingBlock: 5, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                {
                                                                    this.props.showValue ?
                                                                        value :
                                                                        setBodyDataType(value,
                                                                            this.state.table_data.headers[i].type, this.props.disabled,
                                                                            (data) => {

                                                                                if (this.state.table_data.headers[i].type == 'radio') {
                                                                                    var my_table_data = { ...this.state.table_data }
                                                                                    my_table_data.body[index] = this.setRadioFieldData(i, my_table_data.body[index], data)

                                                                                }
                                                                                else {
                                                                                    var my_table_data = { ...this.state.table_data }
                                                                                    my_table_data.body[index][i] = data
                                                                                }

                                                                                this.props.onChange(JSON.stringify(my_table_data))
                                                                            },
                                                                            index,
                                                                        )
                                                                }
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                }
            </div>
        )
    }
}
