import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_BILLING_USER, FETCH_PLAN_DETAILS } from './actionType';
const initialState = {
    billinguser: {},
    plandetails:[]

};
const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BILLING_USER:
            return {
                ...state,
                billinguser: action.payload
            };
            case FETCH_PLAN_DETAILS:
            return {
                ...state,
                plandetails: action.payload
            };

        default:
            return state;
    }
};
export default billingReducer;