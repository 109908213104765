import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateProject } from '../action';
import { setNotification } from '../../common/action';

class ProjectUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            description: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({ label: this.props.selectedData.name, description: this.props.selectedData.description })
        }
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.label,
            "description": this.state.description
        }
        this.props.handleClose();
        if (requestBody["name"].replace(/\s/g, '').length && requestBody["description"].replace(/\s/g, '').length) {
            this.props.editProject(this.props.token.access, this.props.selectedData.id, requestBody);
        }
        else{
            this.props.setNotification(true, "Please enter a valid Project Name", "danger")
        }
      
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update Project</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Form.Group className="mb-3">
                            <Form.Label>Project Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Project Name" required
                                onChange={event => { this.setState({ label: event.target.value }); }}
                                value={this.state.label} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Description"
                                onChange={event => { this.setState({ description: event.target.value }); }}
                                value={this.state.description} />
                        </Form.Group>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editProject: (accessToken, pk, requestBody) => dispatch(updateProject(accessToken, pk, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUpdateModal);
